import SignupDetail from '@/features/authScreen/SignupDetail'
import Logo from '@/features/layout/Logo'
import Page from '@/features/layout/Page'
import {
  Box,
  Card,
  CardContent,
  Container,
  Divider,
  Link,
  Typography,
} from '@mui/material'
import type { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { Link as RouterLink } from 'react-router-dom'

const styles = {
  container: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    minHeight: '100vh',
  },
  cardContainer: {
    paddingBottom: 8,
    paddingTop: 8,
  },
  cardContent: {
    padding: 4,
    display: 'flex',
    flexDirection: 'column',
    minHeight: 400,
  },
  currentMethodIcon: {
    height: 40,
    '& > img': {
      width: 'auto',
      maxHeight: '100%',
    },
  },
}

const Signup: FC = () => {
  const { t } = useTranslation()

  return (
    <Page sx={styles.container} title={t('Sign up')}>
      <Container sx={styles.cardContainer} maxWidth="sm">
        <Box mb={8} display="flex" justifyContent="center">
          <a href="https://goodmenu.io">
            <Logo />
          </a>
        </Box>
        <Card>
          <CardContent sx={styles.cardContent}>
            <Box>
              <div>
                <Typography
                  color="textPrimary"
                  gutterBottom={true}
                  variant="h2"
                >
                  {t('Sign up')}
                </Typography>
              </div>
            </Box>
            <Box flexGrow={1} mt={3}>
              <SignupDetail />
            </Box>
            <Box my={3}>
              <Divider />
            </Box>
            <Link
              component={RouterLink}
              to="/signin"
              variant="body2"
              color="textSecondary"
            >
              {t('ui_auth:Sign in if you already have an account')}
            </Link>
          </CardContent>
        </Card>
      </Container>
    </Page>
  )
}

export default Signup
