import { useUserInfo } from '@/features/auth/state/authStatus'
import { useMutationCreateBackup } from '@/features/backup/api/useMutationCreateBackup'
import { useQueryBackups } from '@/features/backup/api/useQueryBackups'
import { useConfirmCreateBackup } from '@/features/backup/hooks/useConfirmCreateBackup'
import { queryKeys } from '@/libraries/reactQuery/queryKeys'
import { Box, Button, Grid, SvgIcon, Typography } from '@mui/material'
import { useQueryClient } from '@tanstack/react-query'
import { enqueueSnackbar } from 'notistack'
import type { FC } from 'react'
import { PlusCircle as PlusCircleIcon } from 'react-feather'
import { useTranslation } from 'react-i18next'
import { planSettings } from '../account/planSettings.ts'

interface HeaderProps {
  className?: string
}

export const BackupViewHeader: FC<HeaderProps> = ({ className, ...rest }) => {
  const { t } = useTranslation()
  const queryClient = useQueryClient()
  const { confirmCreateBackup } = useConfirmCreateBackup()

  const userInfo = useUserInfo()
  const { data: backups } = useQueryBackups()
  const { isPending: isCreatingBackup, mutate: createBackup } =
    useMutationCreateBackup()

  const { maxBackupCount } = planSettings[userInfo.plan]
  const hitMaxBackupCount = backups.length >= maxBackupCount

  const onCreateBackupButtonClicked = async () => {
    const yes = await confirmCreateBackup()
    if (!yes) {
      return
    }

    const newBackupSeed = { memo: '' }
    createBackup(newBackupSeed, {
      onSuccess: async () => {
        await queryClient.invalidateQueries({
          queryKey: queryKeys.backups(),
        })
        enqueueSnackbar(t('ui_backup:Created backup'), {
          variant: 'success',
        })
      },
    })
  }

  return (
    <Grid
      className={className}
      container={true}
      justifyContent="space-between"
      spacing={3}
      {...rest}
    >
      <Grid item={true} xs={12}>
        <Typography variant="h3" color="textPrimary">
          {t('Backups')}
        </Typography>
      </Grid>
      <Grid item={true}>
        <Button
          color="secondary"
          disabled={isCreatingBackup || hitMaxBackupCount}
          variant="contained"
          onClick={onCreateBackupButtonClicked}
          startIcon={
            <SvgIcon fontSize="small">
              <PlusCircleIcon />
            </SvgIcon>
          }
        >
          {t('ui_backup:Create backup')}
        </Button>

        {hitMaxBackupCount && (
          <Box mt={2}>
            <Typography variant="caption">
              {t(
                'ui_backup:Number of backups is limited to {count} in your plan.',
                { count: maxBackupCount },
              )}
            </Typography>
          </Box>
        )}
      </Grid>
    </Grid>
  )
}
