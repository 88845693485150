// DO NOT EDIT THIS FILE DIRECTLY
// COPY FROM visitor-frontend

import { Decimal } from 'decimal.js'

export const intPriceToRealPrice = (intPrice = 0, decimalScale = 0): number => {
  const preciseIntPrice = new Decimal(intPrice)
  const multiplyer = 10 ** (decimalScale * -1)
  return preciseIntPrice.mul(multiplyer).toNumber()
}

export const realPriceToIntPrice = (
  realPrice = 0,
  decimalScale = 0,
): number => {
  const preciseRealPrice = new Decimal(realPrice)
  const multiplyer = 10 ** decimalScale
  return preciseRealPrice.mul(multiplyer).toNumber()
}
