import { trpcClient } from '@/libraries/trpc/trpcClient.ts'
import { useMutation } from '@tanstack/react-query'

export function useMutationCreatePortalSession() {
  return useMutation({
    mutationFn: async () => {
      const result = await trpcClient.stripe.createPortalSession.mutate({
        returnUrl: window.location.href,
      })

      window.location.assign(result.url)
    },
  })
}
