import { Divider } from '@mui/material'
import type { MDXComponents } from 'mdx/types.js'

import { Blockquote } from '@/features/announcement/mdxComponents/Blockquote'
import { Code } from '@/features/announcement/mdxComponents/Code'
import { Heading } from '@/features/announcement/mdxComponents/Heading'
import { List } from '@/features/announcement/mdxComponents/List'
import { ListItem } from '@/features/announcement/mdxComponents/ListItem'
import { Paragraph } from '@/features/announcement/mdxComponents/Paragraph'

// mdxではこのようにしてスタイルの設定を行う
export const mdxComponents: MDXComponents = {
  h1: (props) => <Heading variant="h1" {...props} />,
  h2: (props) => <Heading variant="h2" {...props} />,
  h3: (props) => <Heading variant="h3" {...props} />,
  h4: (props) => <Heading variant="h4" {...props} />,
  h5: (props) => <Heading variant="h5" {...props} />,
  h6: (props) => <Heading variant="h6" {...props} />,
  ul: (props) => <List variant="ul" {...props} />,
  ol: (props) => <List variant="ol" {...props} />,
  hr: () => <Divider />,
  li: (props) => <ListItem {...props} />,
  p: Paragraph,
  blockquote: Blockquote,
  code: Code,
}
