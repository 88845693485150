import { useIsFetching } from '@tanstack/react-query'
import np from 'nprogress'
import { useEffect } from 'react'

export const useNprogress = () => {
  const isFetching = useIsFetching()
  useEffect(() => {
    let canceled = false

    if (isFetching) {
      setTimeout(() => {
        if (!canceled) {
          np.start()
        }
      }, 1000)
    } else {
      setTimeout(() => {
        if (!canceled) {
          np.done()
        }
      }, 200)
    }

    return () => {
      canceled = true
    }
  }, [isFetching])

  useEffect(() => {
    return () => {
      np.done()
    }
  }, [])
}
