import { atom, useAtom } from 'jotai'
import { useCallback } from 'react'

export type SortDirection = 'asc' | 'desc'
export type SortableField =
  | 'name'
  | 'genre'
  | 'price'
  | 'orderNum'
  | 'isHidden'
  | 'soldOut'
  | 'description'

export type MenuitemsQueryCondition = {
  order: SortDirection
  orderBy: SortableField
  searchWord: string
}

const initialCondition: MenuitemsQueryCondition = {
  order: 'asc',
  orderBy: 'genre',
  searchWord: '',
}

const menuitemsQueryConditionAtom =
  atom<MenuitemsQueryCondition>(initialCondition)

/**
 * 商品一覧画面のクエリ条件を保持するカスタムフック
 */
export const useMenuitemsQueryCondition = () => {
  const [condition, setCondition] = useAtom(menuitemsQueryConditionAtom)

  /**
   * 現在セットされているソート列を考慮しつつ、いい感じにソートをセットする
   */
  const setOrderColumn = useCallback(
    (field: SortableField) => {
      const isChanged = condition.orderBy !== field
      const order = (() => {
        if (isChanged) {
          return 'asc'
        }
        return condition.order === 'asc' ? 'desc' : 'asc'
      })()

      setCondition((prev) => ({
        ...prev,
        order,
        orderBy: field,
      }))
    },
    [setCondition, condition.orderBy, condition.order],
  )

  const setSearchWord = useCallback(
    (word: string) => {
      setCondition((prev) => ({
        ...prev,
        searchWord: word.trim(),
      }))
    },
    [setCondition],
  )

  return {
    condition,
    setOrderColumn,
    setSearchWord,
  }
}
