import { config } from '@/config'
import { Helmet } from 'react-helmet'

const analyticsScript = `
  window.dataLayer = window.dataLayer || [];
  function gtag(){dataLayer.push(arguments);}
  gtag('js', new Date());
  gtag('config', '${config.analyticsGtag}');
`

export const GoogleAnalytics = () => {
  if (!config.analyticsGtag) {
    return null
  }

  return (
    <Helmet>
      <script
        async={true}
        src={`https://www.googletagmanager.com/gtag/js?id=${config.analyticsGtag}`}
      />
      <script>{analyticsScript}</script>
    </Helmet>
  )
}
