import { sendEmailVerification } from '@/features/auth/firebaseAuthUtils'
import { Button } from '@mui/material'
import { type FC, useState } from 'react'
import { useTranslation } from 'react-i18next'

interface Props {
  className?: string
}

export const MailVerifyButton: FC<Props> = ({ className }) => {
  const { t } = useTranslation()

  const [isMailSent, setIsMailSent] = useState(false)

  return (
    <Button
      className={className}
      color="primary"
      disabled={isMailSent}
      onClick={async () => {
        await sendEmailVerification()
        setIsMailSent(true)
      }}
      variant="contained"
    >
      {isMailSent
        ? t('ui_products:Verification mail was sent')
        : t('ui_products:Verify email')}
    </Button>
  )
}
