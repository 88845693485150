import Logo from '@/features/layout/Logo'
import { defaultScreenRoute } from '@/routes/routes'
import {
  AppBar,
  Box,
  Hidden,
  IconButton,
  SvgIcon,
  Toolbar,
} from '@mui/material'
import type { FC } from 'react'
import { Menu as MenuIcon } from 'react-feather'
import { Link as RouterLink } from 'react-router-dom'

import Account from './Account.tsx'
import { ChangeLanguageButton } from './ChangeLanguageButton.tsx'
import { PreviewMenuButton } from './PreviewMenuButton.tsx'

export const toolBarHeight = 64

interface TopBarProps {
  className?: string
  onMobileNavOpen?: () => void
}

const styles = {
  root: {
    boxShadow: 'none',
  },
  toolbar: {
    // これがないとモバイルで自動的に高さが低くなる
    height: toolBarHeight,
  },
}

const TopBar: FC<TopBarProps> = ({ className, onMobileNavOpen, ...rest }) => {
  return (
    <AppBar className={className} sx={styles.root} {...rest}>
      <Toolbar sx={styles.toolbar}>
        <Hidden lgUp={true}>
          <IconButton
            aria-label="sidemenu"
            color="inherit"
            onClick={onMobileNavOpen}
            size="large"
          >
            <SvgIcon fontSize="small">
              <MenuIcon />
            </SvgIcon>
          </IconButton>
        </Hidden>
        <Hidden lgDown={true}>
          <RouterLink to={defaultScreenRoute}>
            <Logo />
          </RouterLink>
        </Hidden>
        <Box ml={2} flexGrow={1} />
        <PreviewMenuButton />
        <ChangeLanguageButton />
        <Box ml={2}>
          <Account />
        </Box>
      </Toolbar>
    </AppBar>
  )
}

export default TopBar
