import { z } from 'zod'

const rawEnv = {
  analyticsGtag: import.meta.env['VITE_ANALYTICS_GTAG'],
  apiDomain: import.meta.env['VITE_API_DOMAIN'],
  apiDomainOld: import.meta.env['VITE_API_DOMAIN_OLD'],
  firebaseApiKey: import.meta.env['VITE_FIREBASE_API_KEY'],
  firebaseAppId: import.meta.env['VITE_FIREBASE_APP_ID'],
  firebaseAuthDomain: import.meta.env['VITE_FIREBASE_AUTH_DOMAIN'],
  firebaseProjectId: import.meta.env['VITE_FIREBASE_PROJECT_ID'],
  imgixDomain: import.meta.env['VITE_IMGIX_DOMAIN'],
  priceIdBasic: import.meta.env['VITE_PRICE_ID_BASIC'],
  sentryDsn: import.meta.env['VITE_SENTRY_DSN'],
  visitorSiteDomain: import.meta.env['VITE_VISITOR_SITE_DOMAIN'],
}

const envSchema = z.object({
  analyticsGtag: z.string(), // 本番でのみ設定し、それ以外では空文字にすることで起動を制御している
  apiDomain: z.string().min(1),
  apiDomainOld: z.string().min(1),
  firebaseApiKey: z.string().min(1),
  firebaseAppId: z.string().min(1),
  firebaseAuthDomain: z.string().min(1),
  firebaseProjectId: z.string().min(1),
  imgixDomain: z.string().min(1),
  priceIdBasic: z.string().min(1),
  sentryDsn: z.string(), // 本番でのみ設定し、それ以外では空文字にすることで起動を制御している
  visitorSiteDomain: z.string().min(1),
})

const parseResult = envSchema.safeParse(rawEnv)

if (!parseResult.success) {
  throw new Error(
    `設定情報の読み込みに失敗しました: ${JSON.stringify(parseResult.error.errors)}`,
  )
}

export const config = parseResult.data
