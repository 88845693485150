import { useMutationDeleteBackup } from '@/features/backup/api/useMutationDeleteBackup'
import { useMutationRestoreBackup } from '@/features/backup/api/useMutationRestoreBackup'
import { useQueryBackups } from '@/features/backup/api/useQueryBackups'
import { useConfirmDeleteBackup } from '@/features/backup/hooks/useConfirmDeleteBackup'
import { useConfirmRestoreBackup } from '@/features/backup/hooks/useConfirmRestoreBackup'
import { queryKeys } from '@/libraries/reactQuery/queryKeys'
import { css } from '@emotion/react'
import {
  Box,
  Button,
  Card,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material'
import { useQueryClient } from '@tanstack/react-query'
import { enqueueSnackbar } from 'notistack'
import type { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { DeleteButton } from './DeleteButton.tsx'
import { MemoColumn } from './MemoColumn.tsx'

interface Props {
  className?: string
}

export const BackupViewViewResults: FC<Props> = ({ className, ...rest }) => {
  const { t } = useTranslation()
  const queryClient = useQueryClient()

  //
  // Restore
  //

  const { mutate: restoreBackup, isPending: isRestoringBackup } =
    useMutationRestoreBackup()
  const { confirmRestoreBackup } = useConfirmRestoreBackup()

  const onClickRestoreBackupButton = async (backupId: string) => {
    const yes = await confirmRestoreBackup()
    if (!yes) {
      return
    }

    restoreBackup(backupId, {
      onSuccess: () => {
        enqueueSnackbar(t('ui_backup:Menu restored from backup'), {
          variant: 'success',
        })
        // Invalidate all cached queries
        queryClient.invalidateQueries()
      },
    })
  }

  //
  // Delete
  //

  const { confirmDeleteBackup } = useConfirmDeleteBackup()

  const { isPending: isDeletingBackup, mutate: deleteBackup } =
    useMutationDeleteBackup()

  const onClickDeleteBackupButton = async (backupId: string) => {
    const yes = await confirmDeleteBackup()
    if (!yes) {
      return
    }

    deleteBackup(backupId, {
      onSuccess: () => {
        enqueueSnackbar(t('ui_backup:Backup is deleted'), {
          variant: 'success',
        })
        queryClient.invalidateQueries({ queryKey: queryKeys.backups() })
      },
    })
  }

  //
  //
  //

  const { data: backups } = useQueryBackups()
  const hasBackups = backups.length > 0

  if (!hasBackups) {
    return (
      <Typography color="textPrimary">
        {t('ui_backup:Backup is not created yet.')}
      </Typography>
    )
  }

  return (
    <Card className={className} {...rest}>
      <Paper
        css={css`
          overflow-x: auto;
        `}
      >
        <Box>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell style={{ minWidth: '100px' }} sortDirection="desc">
                  {t('ui_backup:Created at')}
                </TableCell>
                <TableCell style={{ minWidth: '150px' }}>
                  {t('ui_backup:Memo')}
                </TableCell>
                <TableCell style={{ minWidth: '280px' }} />
                <TableCell />
              </TableRow>
            </TableHead>
            <TableBody>
              {backups.map((backup) => {
                const formattedCreatedAt = new Date(
                  backup.created_at,
                ).toLocaleString()
                return (
                  <TableRow hover={true} key={backup.id}>
                    <TableCell>
                      <Box display="flex" alignItems="center">
                        {formattedCreatedAt}
                      </Box>
                    </TableCell>
                    <TableCell>
                      <MemoColumn backup={backup} />
                    </TableCell>
                    <TableCell align="right">
                      <Button
                        variant="outlined"
                        onClick={() => onClickRestoreBackupButton(backup.id)}
                        disabled={isRestoringBackup || isDeletingBackup}
                      >
                        {t('ui_backup:Restore this backup')}
                      </Button>
                    </TableCell>
                    <TableCell>
                      <DeleteButton
                        disabled={isRestoringBackup || isDeletingBackup}
                        id={backup.id}
                        onDelete={() => onClickDeleteBackupButton(backup.id)}
                      />
                    </TableCell>
                  </TableRow>
                )
              })}
            </TableBody>
          </Table>
        </Box>
      </Paper>
    </Card>
  )
}
