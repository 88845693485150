import { atom, useAtom } from 'jotai'

type MenuPreviewerState = {
  // ユーザーがプレビューを開いていたいと思っているかどうか。
  intention: 'open' | 'close'
  // プレビューはユーザーの意図とは関係なく閉じられる場合がある。
  // 例えばルーティングの変化などにより。
  isOpen: boolean
}

const menuPreviewerStateAtom = atom<MenuPreviewerState>({
  intention: 'open',
  isOpen: true,
})

export const useMenuPreviewer = () => {
  const [menuPreviewerState, setMenuPreviewerState] = useAtom(
    menuPreviewerStateAtom,
  )

  const open = () => {
    setMenuPreviewerState({
      intention: 'open',
      isOpen: true,
    })
  }

  const close = () => {
    setMenuPreviewerState({
      intention: 'close',
      isOpen: false,
    })
  }

  const closeTemporary = () => {
    setMenuPreviewerState((current) => ({
      ...current,
      isOpen: false,
    }))
  }

  const restore = () => {
    setMenuPreviewerState((current) => ({
      ...current,
      isOpen: current.intention === 'open',
    }))
  }

  return {
    close,
    closeTemporary,
    isOpen: menuPreviewerState.isOpen,
    open,
    restore,
  }
}
