import { QuestionTooltip } from '@/components/QuestionIcon'
import { css } from '@emotion/react'
import { Typography } from '@mui/material'
import type React from 'react'

type Props = {
  className?: string
  title: string | React.ReactNode
  tooltipText?: string
}

export const FieldTitle: React.FC<Props> = ({
  className,
  title,
  tooltipText,
}) => {
  const styles = {
    container: css`
      display: flex;
      align-items: center;
    `,
    title: css`
      margin-right: 0.25rem;
    `,
  }

  return (
    <div className={className} css={styles.container}>
      <Typography css={styles.title} variant="h5" color="textPrimary">
        {title}
      </Typography>
      {tooltipText && <QuestionTooltip title={tooltipText} />}
    </div>
  )
}
