export const sendMenuUpdatedMessage = () => {
  const iframe = document.querySelector('iframe')
  if (iframe) {
    // @ts-expect-error TS18047
    iframe.contentWindow.postMessage(
      {
        action: 'MenuUpdated',
        // message: 'Hello',
      },
      '*',
    )
  }
}
