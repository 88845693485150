import { trpcClient } from '@/libraries/trpc/trpcClient.ts'
import { useMutation } from '@tanstack/react-query'

export function useMutationDeleteGenre() {
  return useMutation({
    mutationFn: async (deletingId: string) => {
      await trpcClient.genre.delete.mutate({ id: deletingId })
    },
  })
}
