import { trpcClient } from '@/libraries/trpc/trpcClient.ts'
import { useMutation } from '@tanstack/react-query'

export function useMutationCreateCheckoutSession() {
  return useMutation({
    mutationFn: async (priceId: string) => {
      const result = await trpcClient.stripe.createCheckoutSession.mutate({
        returnUrl: window.location.href,
        priceId: priceId,
      })

      window.location.assign(result.url)
    },
  })
}
