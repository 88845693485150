export const setUserIdForNewRelic = (userId: string) => {
  try {
    // Reactの外の世界からやってくるAPIなのでエラーは無視
    // @ts-expect-error
    // biome-ignore lint/correctness/noUndeclaredVariables: <explanation>
    newrelic.setUserId(userId)
  } catch (e) {
    console.error(`newrelic.setUserIdに失敗しました: ${e}`)
  }
}
