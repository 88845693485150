import { trpcClient } from '@/libraries/trpc/trpcClient.ts'
import { useMutation } from '@tanstack/react-query'

export function useMutationBulkDeleteGenres() {
  return useMutation({
    mutationFn: async (deletingIds: string[]) => {
      const result = await trpcClient.genre.bulkDelete.mutate({
        ids: deletingIds,
      })
      return { count: result.count }
    },
  })
}
