import { config } from '@/config'
import { myKy } from '@/features/api/myKy.ts'
import { useMutation } from '@tanstack/react-query'

export type UploadMenuitemImageResponse = {
  fileName: string
}

export function useMutationUploadMenuitemImage() {
  return useMutation({
    retry: 0,
    mutationFn: (file: File) => {
      const url = `${config.apiDomain}/product/image`
      const formData = new FormData()
      formData.append('image', file)
      return myKy
        .post(url, { body: formData })
        .json<UploadMenuitemImageResponse>()
    },
  })
}
