import { Typography } from '@mui/material'
import type { FC } from 'react'
import { useTranslation } from 'react-i18next'

interface HeaderProps {
  className?: string
}

export const DeploymentViewHeader: FC<HeaderProps> = ({
  className,
  ...rest
}) => {
  const { t } = useTranslation()

  return (
    <div className={className} {...rest}>
      <Typography variant="h3" color="textPrimary">
        {t('ui_deploy:Deployment')}
      </Typography>
    </div>
  )
}
