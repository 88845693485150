type Props = {
  url: string
}

/**
 * Youtube動画をレスポンシブにするためのコンポーネント
 *
 * 参考: https://hirashimatakumi.com/blog/6601.html
 */
export const YouTube = (props: Props) => {
  const { url } = props

  return (
    <div style={{ width: '100%', aspectRatio: '16/9' }} className="youtube">
      <iframe
        style={{ width: '100%', height: '100%' }}
        width="560"
        height="315"
        src={url}
        title="YouTube video player"
        frameBorder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
        referrerPolicy="strict-origin-when-cross-origin"
        allowFullScreen={true}
      />
    </div>
  )
}
