import { firebaseAuth } from '@/libraries/firebase/initFirebase'
import {
  GoogleAuthProvider,
  createUserWithEmailAndPassword as createUserWithEmailAndPasswordRaw,
  sendEmailVerification as sendEmailVerificationRaw,
  sendPasswordResetEmail as sendPasswordResetEmailRaw,
  signInWithEmailAndPassword as signInWithEmailAndPasswordRaw,
  signInWithPopup as signInWithPopupRaw,
  signOut as signOutRaw,
} from 'firebase/auth'

//
// Firebase メール認証
//

export const createUserWithEmailAndPassword = (
  email: string,
  password: string,
) => {
  return createUserWithEmailAndPasswordRaw(firebaseAuth, email, password)
}

export const signInWithEmailAndPassword = (email: string, password: string) => {
  return signInWithEmailAndPasswordRaw(firebaseAuth, email, password)
}

export const resetPassword = (email: string) => {
  return sendPasswordResetEmailRaw(firebaseAuth, email)
}

export const sendEmailVerification = async () => {
  if (firebaseAuth.currentUser) {
    await sendEmailVerificationRaw(firebaseAuth.currentUser)
  }
}

//
// Firebase Google認証
//

/**
 * サインイン・サインアップを兼ねている
 */
export const signInOrUpWithGoogle = () => {
  const provider = new GoogleAuthProvider()
  return signInWithPopupRaw(firebaseAuth, provider)
}

//
// 共通
//

export const signOut = () => {
  return signOutRaw(firebaseAuth)
}
