import { Button, Grid, SvgIcon, Typography } from '@mui/material'
import type { FC } from 'react'
import { PlusCircle as PlusCircleIcon } from 'react-feather'
import { useTranslation } from 'react-i18next'
import { Link as RouterLink } from 'react-router-dom'

interface HeaderProps {
  className?: string
}

export const GenreListViewHeader: FC<HeaderProps> = ({
  className,
  ...rest
}) => {
  const { t } = useTranslation()

  return (
    <Grid
      className={className}
      container={true}
      justifyContent="space-between"
      spacing={3}
      {...rest}
    >
      <Grid item={true} xs={12}>
        <Typography variant="h3" color="textPrimary">
          {t('Genres')}
        </Typography>
      </Grid>
      <Grid item={true}>
        <Button
          color="secondary"
          variant="contained"
          component={RouterLink}
          to="/app/management/genres/new"
          startIcon={
            <SvgIcon fontSize="small">
              <PlusCircleIcon />
            </SvgIcon>
          }
        >
          {t('ui_genres:Add new genre')}
        </Button>
      </Grid>
    </Grid>
  )
}
