import { config } from '@/config'
// biome-ignore lint/style/noNamespaceImport: <explanation>
import * as Sentry from '@sentry/react'

export const setupSentry = () => {
  if (!config.sentryDsn) {
    return
  }

  Sentry.init({
    dsn: config.sentryDsn,
    integrations: [
      Sentry.captureConsoleIntegration({ levels: ['warn', 'error'] }),
    ],
  })
}
