import { mdxComponents } from '@/features/announcement/mdxComponents'
import Page from '@/features/layout/Page'
import { Container } from '@mui/material'
import type { FC } from 'react'
import { useTranslation } from 'react-i18next'
import ReleasePastNoteJa from './releaseNotePastJa.mdx'

const AnnouncementPast: FC = () => {
  const { t } = useTranslation()

  return (
    <Page title={t('ui_dashboard:Past news')}>
      <Container maxWidth={false}>
        <ReleasePastNoteJa components={mdxComponents} />
      </Container>
    </Page>
  )
}

export default AnnouncementPast
