import { Typography } from '@mui/material'
import type { FC, ReactNode } from 'react'

interface HeadingProps {
  children?: ReactNode
  variant: 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6'
}

const styles = {
  h1: {
    marginTop: 6,
    marginBottom: 2,
  },
  h2: {
    marginTop: 6,
    marginBottom: 2,
  },
  h3: {
    marginTop: 6,
    marginBottom: 2,
  },
  h4: {
    marginTop: 4,
    marginBottom: 2,
  },
  h5: {
    marginTop: 2,
    marginBottom: 2,
  },
  h6: {
    marginTop: 2,
    marginBottom: 2,
  },
}

export const Heading: FC<HeadingProps> = ({ children, variant, ...rest }) => {
  return (
    <Typography
      sx={styles[variant]}
      variant={variant}
      color="textPrimary"
      {...rest}
    >
      {children}
    </Typography>
  )
}
