import { useConfirmation } from '@/features/confirmation/ConfirmationContext'
import { css } from '@emotion/react'
import { useTranslation } from 'react-i18next'

export const useConfirmRestoreBackup = () => {
  const { confirm } = useConfirmation()
  const { t } = useTranslation()

  const confirmRestoreBackup = () =>
    confirm({
      buttonTextNegative: t('Cancel'),
      buttonTextPositive: t(
        'ui_backup:Delete all current data and restore backup',
      ),
      description: (
        <div>
          {t(
            'ui_backup:Restores the menu to the state it was in when this backup was created.',
          )}
          <br />
          <span
            css={css`
              color: red;
              font-weight: bold;
              text-decoration: underline;
            `}
          >
            {t('ui_backup:All current menus will be deleted and lost forever.')}
          </span>
          <br />
          {t('ui_backup:Do you want to continue?')}
        </div>
      ),
      title: t('ui_backup:Restoration of menu'),
      variant: 'danger',
    })

  return { confirmRestoreBackup }
}
