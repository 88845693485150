import { Typography } from '@mui/material'
import type { FC } from 'react'

const style = {
  marginBottom: 2,
  '& > a': {
    color: 'secondary.main',
  },
}

export const Paragraph: FC = (props) => {
  return (
    <Typography variant="body1" color="textPrimary" sx={style} {...props} />
  )
}
