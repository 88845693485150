import { TRPCClientError } from '@trpc/client'
import type { CustomErrorPayload } from '@yuuniworks/suzume-trpc-types'

/**
 * サーバーから返されたエラーからカスタムエラーペイロードを取り出す
 */
export const unwrapCustomErrorPayload = (
  error: Error,
): CustomErrorPayload | null => {
  // そもそもTRPCのエラーかチェック
  if (!(error instanceof TRPCClientError)) {
    return null
  }

  // カスタムエラーペイロードの有無をチェック
  if (error?.data?.customErrorPayload?.type === undefined) {
    return null
  }

  // 型をつけて返す (ここは責任感を持ってキャストする)
  return error.data.customErrorPayload as CustomErrorPayload
}
