import type { Backup, Draft } from '@/features/api/types'
import { trpcClient } from '@/libraries/trpc/trpcClient.ts'
import { useMutation } from '@tanstack/react-query'

export const useMutationCreateBackup = () => {
  return useMutation({
    mutationFn: (unsavedItem: Draft<Backup>) => {
      return trpcClient.backup.create.mutate(unsavedItem)
    },
  })
}
