// DO NOT EDIT THIS FILE DIRECTLY
// COPY FROM visitor-frontend

import {
  type CurrencyCode,
  currencySettings,
} from '@/features/currency/_do_not_edit_currencySettings'
import { intPriceToRealPrice } from '@/features/currency/_do_not_edit_currencyUtils'
import type React from 'react'
import { NumericFormat } from 'react-number-format'

type Props = {
  currencyCode: CurrencyCode
  value: number
}

export const CurrencyText: React.FC<Props> = (props) => {
  const { currencyCode, value, ...other } = props

  const currencySetting = currencySettings[currencyCode]

  let currencySymbol = ''
  let decimalScale = 0

  // Defensive coding
  if (currencySetting) {
    currencySymbol = currencySetting.symbol
    decimalScale = currencySetting.decimalScale
  } else {
    console.error('currency setting was not found')
  }

  const realPrice = intPriceToRealPrice(value, decimalScale)

  return (
    <NumericFormat
      {...other}
      decimalScale={decimalScale}
      displayType="text"
      fixedDecimalScale={true}
      prefix={`${currencySymbol} `}
      thousandSeparator={true}
      value={realPrice}
    />
  )
}
