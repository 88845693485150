import { useEffect } from 'react'

export const useScreenRefresher = () => {
  useEffect(() => {
    let lastActionTime = Date.now()

    const refreshOrRecord = () => {
      const currentTime = Date.now()
      const pastTime = currentTime - lastActionTime

      if (pastTime > 1000 * 60 * 60 * 12) {
        window.location.reload()
      } else {
        lastActionTime = currentTime
      }
    }

    // Assume changing tabs or launching browser on mobile device.
    // Note that `focus` event is not fired on these device.
    document.addEventListener('visibilitychange', refreshOrRecord)
    window.addEventListener('focus', refreshOrRecord)
    window.addEventListener('touchstart', refreshOrRecord)
    window.addEventListener('click', refreshOrRecord)

    return () => {
      document.removeEventListener('visibilitychange', refreshOrRecord)
      window.removeEventListener('focus', refreshOrRecord)
      window.removeEventListener('touchstart', refreshOrRecord)
      window.removeEventListener('click', refreshOrRecord)
    }
  }, [])
}
