#### 2023-04-03

- 無料プランを廃止しました。大変恐縮ですが、引き続きご利用の場合は「アカウント」→「有料プランにアップグレードする」から有料プランの契約をお願いいたします。
- 料金が長期未払いとなっているお客様について、一旦サブスクリプションを解除させていただきました。今回は、未払い分の請求はいたしません。お手数ですが、引き続きご利用される場合は改めて「アカウント」→「有料プランにアップグレードする」から有料プランの契約をお願いいたします。

#### 2021-10-25

- メニューの下部に空白が表示される問題を修正しました。
- この問題は、iOS 15 Safari においてアドレスバーを下部に表示する設定にしている場合に発生していました。

#### 2021-06-29

- 写真を小さく表示するレイアウトを選択できるようになりました。「ジャンル → レイアウトの種類」から設定できます。

<img src="/static/images/releaseNote20210629.png" style={{ width: 250 }} />

#### 2021-06-16

- プレビュー機能を実装しました。実際の出来上がりを確認しながらメニュー作成を進めることができます。

<img src="/static/images/releaseNote20210616.gif" style={{ width: '100%' }} />

#### 2021-06-14

- 管理画面を英語で表示できるようになりました。

#### 2021-06-10

- 通貨を選択できるようになりました。「全般設定」→「通貨」から選択できます。

#### 2021-06-04

- HEIC/HEIF 形式の画像に対応しました。これまで、iPhone/iPad で撮影した画像がアップロードできない場合がありましたが、本件により解消されます。

#### 2021-06-02

- 写真ズーム時に説明文を表示できるようになりました。「商品編集ページ」→「詳細説明」で設定できます。
- 使い分け
  - **補足**説明は、価格の下に常に表示されるため、必ずユーザの目に入る。一方、長文を記載するとレイアウトが崩れがちなので短文向き。トッピングの料金の解説などに最適。
  - **詳細**説明は、ユーザが写真をズームした時にだけ表示される。長文でもレイアウトが崩れにくい。個々の商品の魅力などについて詳しく伝えたい場合に最適。

<img
  src="/static/images/releaseNote20210602.png"
  style={{ width: '100%', maxWidth: 200 }}
/>

#### 2021-05-26

- 【有料プラン限定】バックアップを作成できるようになりました。
- 誤ってデータを削除してしまったような場合に、バックアップ作成時点までデータを戻すことができます。
- ベーシックプランでは 2 個まで、プレミアムプランでは 10 個までのバックアップを作成できます。

#### 2021-05-21

- テキストのみを表示するレイアウトを選択することができるようになりました。
- 「ジャンルの編集画面」→「レイアウトの種類」で「写真なし」を選択することで設定できます。
- 写真を掲載しても意味がないジャンルや、写真の用意に時間がかかる場合などにご活用ください。
- ジャンル単位での設定となります。

<img src="/static/images/releaseNote20210521.png" style={{ width: '100%' }} />

#### 2021-05-07

- 【有料プラン限定】 全２１種類のフォントの中から、好きなフォントを選択できるようになりました。「全般設定」→「フォント設定」から設定できます。

#### 2021-04-30

- 管理画面のトップバーにメニューをプレビューするためのボタンを追加しました。

#### 2021-04-26

- 店舗情報を登録できるようになりました。「全般設定」→「店舗情報」から入力できます。店舗情報を入力しておくと、検索エンジン最適化が行われるため、Web 検索で見つけてもらいやすくなります。

#### 2021-04-22

- メニューにアクセスしたユーザの数を確認できるようになりました。2021 年 4 月 21 日以降から順次記録されます。

#### 2021-04-13

- 【有料プラン限定】 商品やジャンルを一時的に非表示にする機能を追加しました。

#### 2021-04-08

- 紙メニューの印刷機能を追加しました。「メニューを使う」→「店内用の紙メニューとして使う」からお試しください。
