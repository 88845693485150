import { Box } from '@mui/material'
import { type ComponentProps, forwardRef } from 'react'
import { Helmet } from 'react-helmet'

type Props = ComponentProps<typeof Box> & {
  title?: string
}

const Page = forwardRef<unknown, Props>(
  ({ children, title = '', ...rest }, ref) => {
    const titleAndServiceName =
      title === '' ? 'GoodMenu' : `${title} | GoodMenu`

    return (
      <Box ref={ref} {...rest}>
        <Helmet>
          <title>{titleAndServiceName}</title>
        </Helmet>
        {children}
      </Box>
    )
  },
)

Page.displayName = 'Page'

export default Page
