import { config } from '@/config'

type Options = {
  width?: number
}

export const getImgixSrc = (
  filename: string,
  options: Options = {},
): string => {
  if (!filename) {
    return ''
  }

  let query = 'auto=fm'

  const { width } = options
  if (width) {
    query += `&w=${width}`
  }

  return `https://${config.imgixDomain}/${filename}?${query}`
}
