import { AnnouncementHeader } from '@/features/announcement/AnnouncementHeader'
import { mdxComponents } from '@/features/announcement/mdxComponents'
import { useAnnouncementManager } from '@/features/announcement/useAnnouncementManager'
import Page from '@/features/layout/Page'
import type { SxPropStyles } from '@/libraries/mui/muiTypes'
import { css } from '@emotion/react'
import { Box, Card, CardContent, Container, Grid, Link } from '@mui/material'
import { type FC, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { Link as RouterLink } from 'react-router-dom'

import ReleaseNodeJa from './releaseNoteJa.mdx'

const styles = {
  root: {
    minHeight: '100%',
    paddingTop: 3,
    paddingBottom: 3,
  },
  gridItem: css`
    height: 100%;
  `,
} satisfies SxPropStyles

const Announcement: FC = () => {
  const { t } = useTranslation()
  const { markAsRead } = useAnnouncementManager()

  useEffect(() => {
    const timer = setTimeout(markAsRead, 1000)
    return () => {
      clearTimeout(timer)
    }
  }, [markAsRead])

  return (
    <Page sx={styles.root} title={t('Announcement')}>
      <Container maxWidth={false}>
        <AnnouncementHeader />
        <Box mt={3} />

        <Grid container={true} spacing={4}>
          <Grid item={true} sm={12} md={6}>
            <Card css={styles.gridItem}>
              <CardContent>
                <Box m={2}>
                  <ReleaseNodeJa components={mdxComponents} />
                </Box>
                <Box mt={3}>
                  <Link
                    variant="body1"
                    color="primary"
                    to="/app/pastReleaseNotes"
                    component={RouterLink}
                  >
                    {t('ui_dashboard:See older news')}
                  </Link>
                </Box>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </Container>
    </Page>
  )
}

export default Announcement
