import { config } from '@/config'
import { firebaseAuth } from '@/libraries/firebase/initFirebase'
import { isPlaywrightTest } from '@/utils/isPlaywrightTest'
import { createTRPCClient, httpLink } from '@trpc/client'
import type { TrpcRouter } from '@yuuniworks/suzume-trpc-types'

export const trpcClient = createTRPCClient<TrpcRouter>({
  links: [
    httpLink({
      url: `${config.apiDomain}/trpc`,

      // You can pass any HTTP headers you wish here
      async headers() {
        // Playwrightテスト時はレスポンスを無条件で返すようにモックするので、認証情報は不要
        if (isPlaywrightTest()) {
          return {}
        }

        // トークンの取得方法はこのあたりを参考にした
        // https://zenn.dev/natuuu0831/articles/a356be3533d299
        const token = (await firebaseAuth.currentUser?.getIdToken()) || ''
        if (!token) {
          return {}
        }

        return {
          Authorization: `Bearer ${token}`,
        }
      },
    }),
  ],
})
