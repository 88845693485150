import { softShadows } from '@/features/theme/shadows'
import typographySettings from '@/features/theme/typography'
import { colors, createTheme } from '@mui/material'

// こんなふうにすれば、'@mui/material'がエクスポートする`Theme`インターフェースに新しいプロパティを追加できる
// https://mui.com/material-ui/customization/theming/#typescript
// declare module '@mui/material/styles' {
//   interface Theme {
//     status: {
//       danger: string;
//     };
//   }
//   interface ThemeOptions {
//     status?: {
//       danger?: string;
//     };
//   }
// }

export const theme = createTheme({
  direction: 'ltr',
  typography: typographySettings,
  components: {
    MuiInputBase: {
      styleOverrides: {
        input: {
          '&::placeholder': {
            opacity: 1,
            color: colors.blueGrey[200],
          },
        },
      },
    },
  },
  palette: {
    mode: 'light',
    action: {
      active: colors.blueGrey[600],
    },
    background: {
      default: '#fffcfa',
      paper: colors.common.white,
    },
    primary: {
      // main: colors.indigo[600]
      main: '#EB6442',
    },
    secondary: {
      // main: '#5850EC',
      main: '#EB6442',
    },
    text: {
      primary: colors.blueGrey[900],
      secondary: colors.blueGrey[600],
    },
  },
  shadows: softShadows,
})
