import { AdminHeader } from '@/features/admin/AdminHeader.tsx'
import { EmailChange } from '@/features/admin/emailChange/EmailChange.tsx'
import Page from '@/features/layout/Page.tsx'
import type { SxPropStyles } from '@/libraries/mui/muiTypes.ts'
import { Container, Grid2 } from '@mui/material'

const styles = {
  root: {
    minHeight: '100%',
    paddingTop: 3,
    paddingBottom: 3,
  },
  card: {
    marginY: 2,
    padding: 2,
  },
} satisfies SxPropStyles

export const Admin = () => {
  return (
    <Page sx={styles.root} title="管理者">
      <Container maxWidth={false}>
        <AdminHeader />
        <Grid2 container={true} spacing={2} mt={2}>
          <Grid2 size={{ md: 6 }}>
            <EmailChange />
          </Grid2>
        </Grid2>
      </Container>
    </Page>
  )
}
