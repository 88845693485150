import { css } from '@emotion/react'
import {
  Button,
  Card,
  IconButton,
  SvgIcon,
  Tooltip,
  Typography,
} from '@mui/material'
import Modal from '@mui/material/Modal'
import { type FC, useState } from 'react'
import { Globe as LanguageIcon } from 'react-feather'
import { useTranslation } from 'react-i18next'

export const ChangeLanguageButton: FC = () => {
  const { i18n } = useTranslation()

  const [isDialogOpen, setIsDialogOpen] = useState(false)

  return (
    <>
      <Tooltip title="Change language / 言語の変更">
        <IconButton
          color="inherit"
          onClick={() => setIsDialogOpen(true)}
          size="large"
        >
          <SvgIcon fontSize="small">
            <LanguageIcon />
          </SvgIcon>
        </IconButton>
      </Tooltip>

      <Modal open={isDialogOpen} onClose={() => setIsDialogOpen(false)}>
        <Card
          css={css`
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            width: 100%;
            max-width: 300px;
          `}
        >
          <div
            css={css`
              display: grid;
              grid-gap: 1rem;
              flex-direction: column;
              padding: 1rem;
            `}
          >
            <Typography>Select your language</Typography>
            <Button
              color="primary"
              variant="contained"
              onClick={() => {
                i18n.changeLanguage('en')
                setIsDialogOpen(false)
              }}
            >
              English
            </Button>
            <Button
              color="primary"
              variant="contained"
              onClick={() => {
                i18n.changeLanguage('ja')
                setIsDialogOpen(false)
              }}
            >
              Japanese(日本語)
            </Button>
          </div>
        </Card>
      </Modal>
    </>
  )
}
