import { useMutationDeleteAccount } from '@/features/account/accountDeletion/useMutationDeleteAccount.ts'
import { useUserInfo } from '@/features/auth/state/authStatus.tsx'
import { Button, Card, CardContent, CardHeader, Divider } from '@mui/material'

export const AccountDeletion = () => {
  const { mutate: deleteOwner } = useMutationDeleteAccount()
  const userInfo = useUserInfo()

  const onClick = () => {
    if (
      prompt(
        `アカウントの削除を行おうとしています。すべてのデータは即時に削除され、**永久に復元できなくなります**。\n\n削除対象アカウントは'${userInfo.email}'です。ログインアカウントが間違っていないか必ず一度ご確認ください。\n\n上記を理解し、続行する場合は「完全に削除します」と入力してください。`,
      ) === '完全に削除します'
    ) {
      deleteOwner(undefined, {
        onSuccess: () => {
          alert('アカウントと関連データを完全に削除しました')
          window.location.reload()
        },
        onError: () => {
          alert('エラーが発生しました')
        },
      })
    }
  }

  return (
    <Card>
      <CardHeader title="その他" />
      <Divider />
      <CardContent>
        <Button onClick={onClick} variant="text">
          アカウントの削除
        </Button>
      </CardContent>
    </Card>
  )
}
