import { trpcClient } from '@/libraries/trpc/trpcClient.ts'
import { useMutation } from '@tanstack/react-query'

export const useMutationRestoreBackup = () => {
  return useMutation({
    mutationFn: (backupId: string) => {
      return trpcClient.backup.restore.mutate({ backupId })
    },
  })
}
