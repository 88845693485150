import Page from '@/features/layout/Page'
import { Box, Container } from '@mui/material'
import type { FC } from 'react'
import { useTranslation } from 'react-i18next'

import { BackupViewHeader } from './BackupViewHeader.tsx'
import { BackupViewViewResults } from './BackupViewViewResults.tsx'

const style = {
  paddingTop: 3,
  paddingBottom: 3,
}
const BackupView: FC = () => {
  const { t } = useTranslation()

  return (
    <Page sx={style} title={t('Backups')}>
      <Container maxWidth={false}>
        <BackupViewHeader />
        <Box mt={3}>
          <BackupViewViewResults />
        </Box>
      </Container>
    </Page>
  )
}

export default BackupView
