import { Box, Button, Typography } from '@mui/material'
// biome-ignore lint/style/noNamespaceImport: <explanation>
import * as Sentry from '@sentry/react'
import type React from 'react'
import { ErrorBoundary } from 'react-error-boundary'

// biome-ignore lint/suspicious/noExplicitAny: <explanation>
declare const window: any

type Props = {
  children: React.ReactNode
}

const styles = {
  errorFallback: {
    padding: 4,
  },
}

export const MyErrorBoundary: React.FC<Props> = ({ children }) => {
  return (
    <ErrorBoundary
      fallback={
        <Box sx={styles.errorFallback}>
          <Typography>エラーが発生しました</Typography>
          <Box mt={2}>
            <Button
              onClick={() => window.location.reload()}
              variant="contained"
            >
              リロードする
            </Button>
          </Box>
        </Box>
      }
      onError={(e) => {
        Sentry.captureException(
          new Error('レンダリングプロセス内でエラーが発生しました', {
            cause: e,
          }),
        )
      }}
    >
      {children}
    </ErrorBoundary>
  )
}
