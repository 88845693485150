import { Paper, type SxProps, type Theme } from '@mui/material'
import type { ComponentProps } from 'react'
import ReactQuill from 'react-quill'

const styles = {
  containerWithError: {
    borderColor: 'red',
  },
  quill: (theme) => ({
    // ツールバーの部分
    '& .ql-toolbar.ql-snow': {
      borderLeft: 'none',
      borderTop: 'none',
      borderRight: 'none',
      borderBottom: `1px solid ${theme.palette.divider}`,
      '& .ql-picker-label:hover': {
        color: theme.palette.secondary.main,
      },
      '& .ql-picker-label.ql-active': {
        color: theme.palette.secondary.main,
      },
      '& .ql-picker-item:hover': {
        color: theme.palette.secondary.main,
      },
      '& .ql-picker-item.ql-selected': {
        color: theme.palette.secondary.main,
      },
      '& button:hover': {
        color: theme.palette.secondary.main,
        '& .ql-stroke': {
          stroke: theme.palette.secondary.main,
        },
      },
      '& button:focus': {
        color: theme.palette.secondary.main,
        '& .ql-stroke': {
          stroke: theme.palette.secondary.main,
        },
      },
      '& button.ql-active': {
        '& .ql-stroke': {
          stroke: theme.palette.secondary.main,
        },
      },
      '& .ql-stroke': {
        stroke: theme.palette.text.primary,
      },
      '& .ql-picker': {
        color: theme.palette.text.primary,
      },
      '& .ql-picker-options': {
        padding: theme.spacing(2),
        backgroundColor: theme.palette.background.default,
        border: 'none',
        boxShadow: theme.shadows[10],
        borderRadius: theme.shape.borderRadius,
      },
    },
    // 文字を打つ部分
    '& .ql-container.ql-snow': {
      border: 'none',
      '& .ql-editor': {
        fontFamily: theme.typography.fontFamily,
        fontSize: 16,
        color: theme.palette.text.primary,
        // テキストエリアの高さの初期値
        // 文字を入力していけば自動拡張する。
        minHeight: 156,
        '&.ql-blank::before': {
          color: theme.palette.text.secondary,
        },
      },
    },
    '& .ql-tooltip': {
      left: 'unset !important',
    },
  }),
} satisfies SxProps<Theme>

const defaultFormats = ['bold', 'underline', 'color', 'align', 'link', 'clean']

const defaultModules = {
  toolbar: [
    ['bold', 'underline', { color: [] }],
    [{ align: [] }],
    ['link'],
    ['clean'],
  ],
}

type Props = ComponentProps<typeof ReactQuill> & {
  // エラーがあるときに枠を赤色にするための独自拡張
  error: boolean
}

const QuillEditor = (props: Props) => {
  const {
    className,
    error,
    formats = defaultFormats,
    modules = defaultModules,
    ...rest
  } = props

  return (
    <Paper
      className={className}
      variant="outlined"
      // Paperにスタイルを適用するのではなく、Quillにスタイルを適用している。
      // sxプロパティを使えるのがここしか無いのでやむなくそうしたもの。
      sx={[styles.quill, error && styles.containerWithError]}
    >
      <ReactQuill modules={modules} formats={formats} {...rest} />
    </Paper>
  )
}

export default QuillEditor
