import { config } from '@/config'
import ky from 'ky'
import { type FC, useEffect, useState } from 'react'

const HealthCheckView: FC = () => {
  const [health, setHealth] = useState('')

  useEffect(() => {
    ;(async () => {
      const url = `${config.apiDomainOld}/v1/health_check/`
      try {
        const serverSideHealth = await ky.get(url).json()
        const isServerLive = serverSideHealth === 'ok'
        setHealth(isServerLive ? 'good' : 'bad')
      } catch {
        setHealth('bad')
      }
    })()
  })
  return (
    <div>
      health: <span id="health">{health}</span>
    </div>
  )
}

export default HealthCheckView
