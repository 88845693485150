import { trpcClient } from '@/libraries/trpc/trpcClient.ts'
import { useMutation } from '@tanstack/react-query'

export const useMutationDeleteBackup = () => {
  return useMutation({
    mutationFn: (deletingId: string) => {
      return trpcClient.backup.delete.mutate({ backupId: deletingId })
    },
  })
}
