import { AuthManager } from '@/features/auth/AuthManager'
import GlobalStyles from '@/features/layout/GlobalStyles'
import { theme } from '@/features/theme/theme'
import '@/libraries/firebase/initFirebase'
import { GoogleAnalytics } from '@/libraries/googleAnalytics/GoogleAnalytics'
import { MyErrorBoundary } from '@/libraries/reactQuery/MyErrorBoundary' // firebaseの初期化
import { renderRoutes } from '@/routes/routes'
import { useScreenRefresher } from '@/utils/useScreenRefresher'
import { CssBaseline, StyledEngineProvider, ThemeProvider } from '@mui/material'
import { type FC, Suspense } from 'react'
import { BrowserRouter } from 'react-router-dom'
import { ConfirmationProvider } from './features/confirmation/ConfirmationContext.tsx'
import LoadingScreen from './features/layout/LoadingScreen.tsx'
import { MySnackbarProvider } from './features/snackbar/MySnackbarProvider.tsx'
import { setupI18n } from './libraries/i18n/setupI18n.ts'
import { ReactQuery } from './libraries/reactQuery/ReactQuery.tsx'
import { setupSentry } from './libraries/sentry/setupSentry.ts'

setupSentry()
setupI18n()

const App: FC = () => {
  useScreenRefresher()

  return (
    <MyErrorBoundary>
      <ReactQuery>
        <StyledEngineProvider injectFirst={true}>
          <ThemeProvider theme={theme}>
            <CssBaseline />
            <BrowserRouter>
              <MySnackbarProvider>
                <ConfirmationProvider>
                  <Suspense fallback={<LoadingScreen />}>
                    <AuthManager>
                      <GlobalStyles />
                      <GoogleAnalytics />
                      {renderRoutes()}
                    </AuthManager>
                  </Suspense>
                </ConfirmationProvider>
              </MySnackbarProvider>
            </BrowserRouter>
          </ThemeProvider>
        </StyledEngineProvider>
      </ReactQuery>
    </MyErrorBoundary>
  )
}

export default App
