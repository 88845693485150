import { css } from '@emotion/react'
import type React from 'react'
import { forwardRef } from 'react'

type Props = {} & React.AllHTMLAttributes<HTMLDivElement>

// biome-ignore lint/suspicious/noExplicitAny: <explanation>
const ForwardableSquareDiv: React.ForwardRefRenderFunction<any, Props> = (
  { className, children, ...rest },
  ref,
) => {
  const styles = {
    imageContainer: css`
      outline: none;
      overflow: hidden;
      position: relative;
      width: 100%;
      /* make it square */
      /* https://spin.atomicobject.com/2015/07/14/css-responsive-square/ */
      :after {
        content: '';
        display: block;
        padding-bottom: 100%;
      }
    `,
    content: css`
      position: absolute;
      width: 100%;
      height: 100%;
    `,
  }

  return (
    <div className={className} css={styles.imageContainer} ref={ref}>
      <div {...rest} css={styles.content}>
        {children}
      </div>
    </div>
  )
}

export const SquareDiv = forwardRef(ForwardableSquareDiv)
