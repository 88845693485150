import { config } from '@/config'
import { useQueryCurrentQrCode } from '@/features/api/commonQueries/useQueryCurrentQrCode.ts'

// TODO: このhooksはクエリの仲間じゃないので他の場所に移動させたい
export const useQueryVisitorSiteInfo = () => {
  const { data: qrCode } = useQueryCurrentQrCode()

  const qrcodeImageSrc = `${config.apiDomain}/qr-code/${qrCode.id}/image`
  const visitorSiteUrl = `${config.visitorSiteDomain}/qrcode/${qrCode.id}`
  const visitorSiteUrlPrint = `${visitorSiteUrl}?isPrintPreview=true`

  return {
    qrcodeImageSrc,
    visitorSiteUrl,
    visitorSiteUrlPrint,
  }
}
