import LoadingScreen from '@/features/layout/LoadingScreen'
import { useMenuPreviewer } from '@/features/menuPreviewer/useMenuPreviewer'
import type { SxPropStyles } from '@/libraries/mui/muiTypes'
import { useNprogress } from '@/libraries/nprogress/useNprogress'
import { MyErrorBoundary } from '@/libraries/reactQuery/MyErrorBoundary'
import { Box } from '@mui/material'
import { Suspense, useState } from 'react'
import { Outlet } from 'react-router-dom'
import { MenuPreviewer } from '../menuPreviewer/MenuPreviewer.tsx'
import NavBar from './NavBar/index.tsx'
import TopBar, { toolBarHeight } from './TopBar/index.tsx'

export const DashboardLayout = () => {
  const [isMobileNavOpen, setMobileNavOpen] = useState<boolean>(false)
  const { isOpen: isMenuPreviewerOpen } = useMenuPreviewer()

  useNprogress()

  return (
    <Box>
      {/* position: fixed */}
      <TopBar onMobileNavOpen={() => setMobileNavOpen(true)} />

      {/* position: fixed */}
      <NavBar
        onMobileClose={() => setMobileNavOpen(false)}
        openMobile={isMobileNavOpen}
      />

      {/* position: fixed */}
      <Box sx={styles.menuPreviewer}>
        <MenuPreviewer />
      </Box>

      <Box
        sx={[
          styles.mainContentArea,
          isMenuPreviewerOpen && styles.mainContentAreaWithMenuPreviewer,
        ]}
      >
        <Suspense fallback={<LoadingScreen />}>
          <MyErrorBoundary>
            <Outlet />
          </MyErrorBoundary>
        </Suspense>
      </Box>
    </Box>
  )
}

const styles = {
  mainContentArea: {
    paddingTop: `${toolBarHeight}px`,
    // md以下ではナビゲーションは非表示になるため
    paddingLeft: { md: 0, lg: 32 },
  },
  mainContentAreaWithMenuPreviewer: {
    // xs以下ではプレビューは必ず非表示になるため、パディングはそもそも不要
    paddingRight: { xs: 'none', md: '348px' },
  },
  menuPreviewer: { display: { xs: 'none', md: 'block' } },
} satisfies SxPropStyles
