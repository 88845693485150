import SigninDetail from '@/features/authScreen/SigninDetail'
import Logo from '@/features/layout/Logo'
import Page from '@/features/layout/Page'
import {
  Box,
  Card,
  CardContent,
  Container,
  Divider,
  Link,
  Typography,
} from '@mui/material'
import type { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { Link as RouterLink } from 'react-router-dom'

const styles = {
  container: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    minHeight: '100vh',
  },
  cardContainer: {
    paddingBottom: 8,
    paddingTop: 8,
  },
  cardContent: {
    padding: 4,
    display: 'flex',
    flexDirection: 'column',
    minHeight: 400,
  },
}

const Signin: FC = () => {
  const { t } = useTranslation()

  return (
    <Page sx={styles.container} title={t('Sign in')}>
      <Container sx={styles.cardContainer} maxWidth="sm">
        <Box mb={8} display="flex" justifyContent="center">
          <a href="https://goodmenu.io">
            <Logo />
          </a>
        </Box>
        <Card>
          <CardContent sx={styles.cardContent}>
            <Box>
              <Typography color="textPrimary" gutterBottom={true} variant="h2">
                {t('Sign in')}
              </Typography>
            </Box>
            <Box flexGrow={1} mt={3}>
              <SigninDetail />
            </Box>
            <Box my={3}>
              <Divider />
            </Box>
            <Link
              component={RouterLink}
              to="/signup"
              variant="body2"
              color="textSecondary"
            >
              {t("ui_auth:Don't have an account ?")}
            </Link>
            <Box mt={1}>
              <Link
                component={RouterLink}
                to="/resetPassword"
                variant="body2"
                color="textSecondary"
              >
                {t('ui_auth:Forgot password?')}
              </Link>
            </Box>
          </CardContent>
        </Card>
      </Container>
    </Page>
  )
}

export default Signin
