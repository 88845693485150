import { trpcClient } from '@/libraries/trpc/trpcClient.ts'
import { useMutation } from '@tanstack/react-query'

export const useMutationDeleteAccount = () => {
  return useMutation({
    mutationFn: async () => {
      await trpcClient.owner.deleteOwner.mutate()
    },
  })
}
