import type { GenresQueryCondition } from '@/features/genreList/useGenresQueryCondition'
import type { MenuitemsQueryCondition } from '@/features/menuitemList/useMenuitemsQueryCondition'

export const queryKeys = {
  backups: () => ['backups'],
  genre: (id: string) => ['genre', id],
  genres: (condition: GenresQueryCondition) => ['genres', condition],
  genresAll: () => ['genres'],
  menu: () => ['menu'],
  menuitem: (id: string) => ['menuitem', id],
  menuitems: (condition: MenuitemsQueryCondition) => ['menuitems', condition],
  menuitemsAll: () => ['menuitems'],
  qrcode: () => ['qrCode'],
}
