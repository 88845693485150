// DO NOT EDIT THIS FILE DIRECTLY
// COPY FROM visitor-frontend

export const googleFontSettings: {
  [key in string]: {
    queryString: string
    fontFamily: string
    name: string
    defaultFont: 'sans-serif' | 'serif' | 'cursive'
  }
} = {
  notoSans: {
    defaultFont: 'sans-serif',
    fontFamily: "'Noto Sans JP'",
    name: 'Noto Sans JP',
    queryString: 'Noto+Sans+JP',
  },
  newTegomin: {
    defaultFont: 'serif',
    fontFamily: "'New Tegomin'",
    name: 'New Tegomin',
    queryString: 'New+Tegomin',
  },
  notoSerif: {
    defaultFont: 'serif',
    fontFamily: "'Noto Serif JP'",
    name: 'Noto Serif JP',
    queryString: 'Noto+Serif+JP',
  },
  reggaeOne: {
    defaultFont: 'cursive',
    fontFamily: "'Reggae One'",
    name: 'Reggae One',
    queryString: 'Reggae+One',
  },
  mPlusRounded1c: {
    defaultFont: 'sans-serif',
    fontFamily: "'M PLUS Rounded 1c'",
    name: 'M PLUS Rounded 1c',
    queryString: 'M+PLUS+Rounded+1c',
  },
  sawarabiMincho: {
    defaultFont: 'sans-serif',
    fontFamily: "'Sawarabi Mincho'",
    name: 'Sawarabi Mincho',
    queryString: 'Sawarabi+Mincho',
  },
  sawarabiGothic: {
    defaultFont: 'sans-serif',
    fontFamily: "'Sawarabi Gothic'",
    name: 'Sawarabi Gothic',
    queryString: 'Sawarabi+Gothic',
  },
  kosugiMaru: {
    defaultFont: 'sans-serif',
    fontFamily: "'Kosugi Maru'",
    name: 'Kosugi Maru',
    queryString: 'Kosugi+Maru',
  },
  shipporiMincho: {
    defaultFont: 'serif',
    fontFamily: "'Shippori Mincho'",
    name: 'Shippori Mincho',
    queryString: 'Shippori+Mincho',
  },
  shipporiMinchoB1: {
    defaultFont: 'serif',
    fontFamily: "'Shippori Mincho B1'",
    name: 'Shippori Mincho B1',
    queryString: 'Shippori+Mincho+B1',
  },
  delaGothicOne: {
    defaultFont: 'cursive',
    fontFamily: "'Dela Gothic One'",
    name: 'Dela Gothic One',
    queryString: 'Dela+Gothic+One',
  },
  kiwiMaru: {
    defaultFont: 'serif',
    fontFamily: "'Kiwi Maru'",
    name: 'Kiwi Maru',
    queryString: 'Kiwi+Maru',
  },
  rocknRollOne: {
    defaultFont: 'sans-serif',
    fontFamily: "'RocknRoll One'",
    name: 'RocknRoll One',
    queryString: 'RocknRoll+One',
  },
  dotGothic16: {
    defaultFont: 'sans-serif',
    fontFamily: "'DotGothic16'",
    name: 'DotGothic16',
    queryString: 'DotGothic16',
  },
  stick: {
    defaultFont: 'sans-serif',
    fontFamily: "'Stick'",
    name: 'Stick',
    queryString: 'Stick',
  },
  trainOne: {
    defaultFont: 'cursive',
    fontFamily: "'Train One'",
    name: 'Train One',
    queryString: 'Train+One',
  },
  pottaOne: {
    defaultFont: 'cursive',
    fontFamily: "'Potta One'",
    name: 'Potta One',
    queryString: 'Potta+One',
  },
  hachiMaruPop: {
    defaultFont: 'cursive',
    fontFamily: "'Hachi Maru Pop'",
    name: 'Hachi Maru Pop',
    queryString: 'Hachi+Maru+Pop',
  },
  yuseiMagic: {
    defaultFont: 'sans-serif',
    fontFamily: "'Yusei Magic'",
    name: 'Yusei Magic',
    queryString: 'Yusei+Magic',
  },
  kosugi: {
    defaultFont: 'sans-serif',
    fontFamily: "'Kosugi'",
    name: 'Kosugi',
    queryString: 'Kosugi',
  },
}
