import { useConfirmation } from '@/features/confirmation/ConfirmationContext'
import { useTranslation } from 'react-i18next'

export const useConfirmCreateBackup = () => {
  const { confirm } = useConfirmation()
  const { t } = useTranslation()

  const confirmCreateBackup = () =>
    confirm({
      buttonTextNegative: t('Cancel'),
      buttonTextPositive: t('ui_backup:Create backup'),
      description: t(
        'ui_backup:Are you sure you want to make a backup of the current menu?',
      ),
      title: t('ui_backup:Creation of backup'),
      variant: 'info',
    })

  return { confirmCreateBackup }
}
