// DO NOT EDIT THIS FILE DIRECTLY
// COPY FROM visitor-frontend

const currencyCodes = [
  'AED',
  'ARS',
  'AUD',
  'BRL',
  'CAD',
  'CHF',
  'CLP',
  'CNY',
  'COP',
  'CZK',
  'DKK',
  'EGP',
  'EUR',
  'GBP',
  'HKD',
  'HUF',
  'IDR',
  'ILS',
  'INR',
  'JPY',
  'KRW',
  'KWD',
  'MXP',
  'MYR',
  'NOK',
  'NZD',
  'PEN',
  'PKR',
  'PLN',
  'QAR',
  'RUB',
  'SAR',
  'SGD',
  'THB',
  'TRY',
  'TWD',
  'USD',
  'ZAR',
] as const

export type CurrencyCode = (typeof currencyCodes)[number]

export const currencySettings: {
  [key in CurrencyCode]: {
    code: string
    decimalScale: number
    name: string
    symbol: string
  }
} = {
  AED: {
    code: 'AED',
    decimalScale: 2,
    name: 'United Arab Emirates dirham',
    symbol: 'د.إ',
  },
  ARS: {
    code: 'ARS',
    decimalScale: 2,
    name: 'Argentine peso',
    symbol: '$',
  },
  AUD: {
    code: 'AUD',
    decimalScale: 2,
    name: 'Australian dollar',
    symbol: 'A$',
  },
  BRL: {
    code: 'BRL',
    decimalScale: 2,
    name: 'Brazilian real',
    symbol: 'R$',
  },
  CAD: {
    code: 'CAD',
    decimalScale: 2,
    name: 'Canadian dollar',
    symbol: 'CA$',
  },
  CHF: {
    code: 'CHF',
    decimalScale: 2,
    name: 'Swiss franc',
    symbol: 'F',
  },
  CLP: {
    code: 'CLP',
    decimalScale: 0,
    name: 'Chilean peso',
    symbol: 'CLP$',
  },
  CNY: {
    code: 'CNY',
    decimalScale: 2,
    name: 'Chinese yuan',
    symbol: '¥',
  },
  COP: {
    code: 'COP',
    decimalScale: 2,
    name: 'Colombian peso',
    symbol: 'COP$',
  },
  CZK: {
    code: 'CZK',
    decimalScale: 2,
    name: 'Czech koruna',
    symbol: 'Kč',
  },
  DKK: {
    code: 'DKK',
    decimalScale: 2,
    name: 'Danish krone',
    symbol: 'kr',
  },
  EGP: {
    code: 'EGP',
    decimalScale: 2,
    name: 'Egyptian pound',
    symbol: 'E£',
  },
  EUR: {
    code: 'EUR',
    decimalScale: 2,
    name: 'Euro',
    symbol: '€',
  },
  GBP: {
    code: 'GBP',
    decimalScale: 2,
    name: 'Pound sterling',
    symbol: '£',
  },
  HKD: {
    code: 'HKD',
    decimalScale: 2,
    name: 'Hong Kong dollar',
    symbol: 'HK$',
  },
  HUF: {
    code: 'HUF',
    decimalScale: 2,
    name: 'Hungarian forint',
    symbol: 'Ft',
  },
  IDR: {
    code: 'IDR',
    decimalScale: 0,
    name: 'Indonesian rupiah',
    symbol: 'Rp',
  },
  ILS: {
    code: 'ILS',
    decimalScale: 2,
    name: 'Israeli new shekel',
    symbol: '₪',
  },
  INR: {
    code: 'INR',
    decimalScale: 2,
    name: 'Indian rupee',
    symbol: '₹',
  },
  JPY: {
    code: 'JPY',
    decimalScale: 0,
    name: 'Japanese Yen',
    symbol: '¥',
  },
  KRW: {
    code: 'KRW',
    decimalScale: 0,
    name: 'South Korean won',
    symbol: '₩',
  },
  KWD: {
    code: 'KWD',
    decimalScale: 3,
    name: 'Kuwaiti dinar',
    symbol: 'د.ك',
  },
  MXP: {
    code: 'MXP',
    decimalScale: 2,
    name: 'Mexican peso',
    symbol: 'Mex$',
  },
  MYR: {
    code: 'MYR',
    decimalScale: 2,
    name: 'Malaysian ringgit',
    symbol: 'RM',
  },
  NOK: {
    code: 'NOK',
    decimalScale: 2,
    name: 'Norwegian krone',
    symbol: 'kr',
  },
  NZD: {
    code: 'NZD',
    decimalScale: 2,
    name: 'New Zealand dollar',
    symbol: 'NZ$',
  },
  PEN: {
    code: 'PEN',
    decimalScale: 2,
    name: 'Peruvian sol',
    symbol: 'S/',
  },
  PKR: {
    code: 'PKR',
    decimalScale: 2,
    name: 'Pakistani rupee',
    symbol: '₨',
  },
  PLN: {
    code: 'PLN',
    decimalScale: 2,
    name: 'Polish złoty',
    symbol: 'zł',
  },
  QAR: {
    code: 'QAR',
    decimalScale: 2,
    name: 'Qatari riyal',
    symbol: 'ر.ق',
  },
  RUB: {
    code: 'RUB',
    decimalScale: 2,
    name: 'Russian ruble',
    symbol: '₽',
  },
  SAR: {
    code: 'SAR',
    decimalScale: 2,
    name: 'Saudi riyal',
    symbol: 'ر.س',
  },
  SGD: {
    code: 'SGD',
    decimalScale: 2,
    name: 'Singapore dollar',
    symbol: 'S$',
  },
  THB: {
    code: 'THB',
    decimalScale: 2,
    name: 'Thai baht',
    symbol: '฿',
  },
  TRY: {
    code: 'TRY',
    decimalScale: 2,
    name: 'Turkish lira',
    symbol: '₺',
  },
  TWD: {
    code: 'TWD',
    decimalScale: 2,
    name: 'New Taiwan dollar',
    symbol: 'NT$',
  },
  USD: {
    code: 'USD',
    decimalScale: 2,
    name: 'Dollar',
    symbol: '$',
  },
  ZAR: {
    code: 'ZAR',
    decimalScale: 2,
    name: 'South African rand',
    symbol: 'R',
  },
}
