import { trpcClient } from '@/libraries/trpc/trpcClient.ts'
import { useMutation } from '@tanstack/react-query'

export function useMutationDeleteMenuitem() {
  return useMutation({
    mutationFn: (deletingId: string) => {
      return trpcClient.product.delete.mutate({ id: deletingId })
    },
  })
}
