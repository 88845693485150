import { useConfirmation } from '@/features/confirmation/ConfirmationContext'
import { useTranslation } from 'react-i18next'

export const useConfirmDeleteBackup = () => {
  const { confirm } = useConfirmation()
  const { t } = useTranslation()

  const confirmDeleteBackup = () =>
    confirm({
      buttonTextNegative: t('Cancel'),
      buttonTextPositive: t('ui_backup:Delete backup'),
      description: t('ui_backup:Are you sure you want to delete this backup?'),
      title: t('ui_backup:Deletion of backup'),
      variant: 'danger',
    })

  return { confirmDeleteBackup }
}
