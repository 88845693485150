import { Box, LinearProgress, type SxProps, type Theme } from '@mui/material'
import type { FC } from 'react'

const LoadingScreen: FC = () => {
  return (
    <Box sx={styles.container}>
      <Box width={400}>
        <LinearProgress />
      </Box>
    </Box>
  )
}

const styles = {
  container: (theme) => ({
    alignItems: 'center',
    background: theme.palette.background.default,
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    justifyContent: 'center',
    minHeight: '100%',
    padding: theme.spacing(3),
  }),
} satisfies { [key in string]: SxProps<Theme> }

export default LoadingScreen
