import i18next from 'i18next'
import LanguageDetector from 'i18next-browser-languagedetector'
import HttpBackend from 'i18next-http-backend'
import { initReactI18next } from 'react-i18next'

export const setupI18n = () => {
  i18next
    .use(initReactI18next)
    .use(HttpBackend)
    .use(LanguageDetector)
    .init({
      // i18nextのv21以降、これがないとキーにスペースが含まれる翻訳辞書が読み込めない事象が発生したため追加した
      // https://github.com/i18next/i18next/issues/1665#issuecomment-939543144
      nsSeparator: ':',
      ns: [
        'common',
        'plans',
        'ui_account',
        'ui_auth',
        'ui_backup',
        'ui_dashboard',
        'ui_deploy',
        'ui_general',
        'ui_genres',
        'ui_products',
        'ui_support',
        'ui_tour',
      ],
      defaultNS: 'common',
      backend: {
        loadPath: '/locales/{{lng}}/{{ns}}.json',
      },
      fallbackLng: 'ja',
      interpolation: {
        // Not required for React
        escapeValue: false,
      },
    })
}
