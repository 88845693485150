import { Box } from '@mui/material'
import type { FC, ReactNode } from 'react'

interface ListProps {
  children?: ReactNode
  variant: 'ul' | 'ol'
}

const style = {
  marginLeft: 4,
  marginBottom: 2,
}

export const List: FC<ListProps> = ({
  variant: Component,
  children,
  ...rest
}) => {
  return (
    <Box component={Component} sx={style} {...rest}>
      {children}
    </Box>
  )
}
