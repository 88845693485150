import NavigateNextIcon from '@mui/icons-material/NavigateNext'
import { Breadcrumbs, Link, Typography } from '@mui/material'
import type { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { Link as RouterLink } from 'react-router-dom'

interface HeaderProps {
  className?: string
  menuitemName: string
}

export const MenuitemDetailViewHeader: FC<HeaderProps> = ({
  className,
  menuitemName,
  ...rest
}) => {
  const { t } = useTranslation()

  return (
    <div className={className} {...rest}>
      <Breadcrumbs
        separator={<NavigateNextIcon fontSize="small" />}
        aria-label="breadcrumb"
      >
        <Link
          variant="body1"
          color="inherit"
          to="/app/management/menuitems"
          component={RouterLink}
        >
          {t('Products')}
        </Link>
        <Typography variant="h3" color="textPrimary">
          {menuitemName}
        </Typography>
      </Breadcrumbs>
    </div>
  )
}
