import { css } from '@emotion/react'
import { Box, Typography } from '@mui/material'
import type { FC, ReactNode } from 'react'

type Props = {
  className?: string
  notes: Array<string | ReactNode>
}

export const DeploymentViewNotes: FC<Props> = ({ className, notes }) => {
  const styles = {
    notes: css`
      background: rgba(0, 0, 0, 0.03);
      padding: 1rem;
      border-radius: 1rem;
      p:not(:first-of-type) {
        margin-top: 1rem;
      }
    `,
    note: css`
      color: rgba(0, 0, 0, 0.54);
      padding-left: 1rem;
      text-indent: -1rem;
      :before {
        content: '📝';
      }
      a:link {
        color: rgba(0, 0, 0, 0.54);
      }
      a:visited {
        color: rgba(0, 0, 0, 0.54);
      }
      a:hover {
        color: rgba(0, 0, 0, 0.54);
      }
      a:active {
        color: rgba(0, 0, 0, 0.54);
      }
    `,
  }

  return (
    <Box className={className} sx={styles.notes}>
      {notes.map((note, i) => {
        return (
          <Typography variant="body2" key={i} css={styles.note}>
            {note}
          </Typography>
        )
      })}
    </Box>
  )
}
