import { useQueryVisitorSiteInfo } from '@/features/api/commonQueries/useQueryVisitorSiteInfo'
import { css } from '@emotion/react'

export const MenuPreviewerPhone = () => {
  const { visitorSiteUrl } = useQueryVisitorSiteInfo()

  return (
    <div
      css={css`
        overflow: hidden;
        position: relative;
      `}
    >
      <img
        alt="Google"
        css={css`
          width: 300px;
          z-index: 1;
        `}
        src="/static/images/phoneFrame.svg"
      />
      <iframe
        frameBorder={0}
        css={css`
          transform: scale(0.71);
          transform-origin: top left;
          top: 24px;
          left: 17px;
          position: absolute;
          z-index: 0;
        `}
        height="667"
        src={visitorSiteUrl}
        title="preview window"
        width="375"
      />
    </div>
  )
}
