import { SubscriptionNew } from '@/features/account/subscription/SubscriptionNew'
import { SubscriptionUpdate } from '@/features/account/subscription/SubscriptionUpdate'
import type { PlanName } from '@/features/api/types'
import { useUserInfo } from '@/features/auth/state/authStatus'
import { css } from '@emotion/react'
import {
  Box,
  Card,
  CardContent,
  CardHeader,
  Divider,
  Typography,
} from '@mui/material'
import type { FC, ReactNode } from 'react'
import { useTranslation } from 'react-i18next'

interface Props {
  currentPlan: PlanName
  className?: string
}

export const Subscription: FC<Props> = ({ ...rest }) => {
  const { t } = useTranslation()
  const userInfo = useUserInfo()
  const isFreePlanUser = userInfo.contractStatus !== 'active'

  const { emailVerified } = userInfo.firebaseUser

  let content: ReactNode = null

  switch (true) {
    case !emailVerified:
      content = (
        <Typography variant="caption">
          メールアドレスの確認を済ませていただくとプランの変更が可能になります。
        </Typography>
      )
      break
    case isFreePlanUser:
      content = <SubscriptionNew css={styles.row} />
      break
    default:
      content = <SubscriptionUpdate css={styles.row} />
  }

  return (
    <Card {...rest}>
      <CardHeader title={t('ui_account:Manage subscription')} />
      <Divider />
      <CardContent>
        <Typography>
          <Box mr={1} component="span">
            現在の契約状態:
          </Box>
          {userInfo.contractStatus === 'active'
            ? '契約中（または契約終了までの残日数あり）'
            : '未契約'}
        </Typography>
        {content}
      </CardContent>
    </Card>
  )
}

const styles = {
  row: css`
    margin-top: 1rem;
  `,
}
