import MoreVertIcon from '@mui/icons-material/MoreVert'
import { IconButton, Menu, MenuItem } from '@mui/material'
import { type FC, useState } from 'react'
import { useTranslation } from 'react-i18next'

interface Props {
  disabled?: boolean
  id: string
  onDelete: (id: string) => void
}

export const DeleteButton: FC<Props> = ({ disabled = false, id, onDelete }) => {
  const { t } = useTranslation()
  const [anchorEl, setAnchorEl] = useState(null)

  // @ts-expect-error anyじゃなくしたい
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const onDeleteButtonClicked = () => {
    onDelete(id)
    handleClose()
  }

  return (
    <>
      <IconButton onClick={handleClick} disabled={disabled} size="large">
        <MoreVertIcon />
      </IconButton>
      <Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={handleClose}>
        <MenuItem onClick={onDeleteButtonClicked}>{t('Delete')}</MenuItem>
      </Menu>
    </>
  )
}
