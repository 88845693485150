import { queryKeys } from '@/libraries/reactQuery/queryKeys'
import { trpcClient } from '@/libraries/trpc/trpcClient'
import { useSuspenseQuery } from '@tanstack/react-query'

// ほしいデータの形をここで定義
type Backup = {
  id: string
  memo: string
  created_at: string
}

export const useQueryBackups = () => {
  return useSuspenseQuery({
    queryKey: queryKeys.backups(),
    queryFn: () => {
      return trpcClient.backup.getList.query()
    },
    select: (rawRecords): Backup[] => {
      return rawRecords.map((rawRecord) => {
        return {
          id: rawRecord.id,
          memo: rawRecord.memo,
          created_at: rawRecord.createdAt,
        }
      })
    },
  })
}
