import { config } from '@/config.ts'
import { useMutationChangeEmail } from '@/features/admin/emailChange/api/useMutationChangeEmail.ts'
import { useMutationChangeEmailDry } from '@/features/admin/emailChange/api/useMutationChangeEmailDry.ts'
import type { SxPropStyles } from '@/libraries/mui/muiTypes.ts'
import { Box, Button, Card, TextField, Typography } from '@mui/material'
import { useState } from 'react'

const styles = {
  root: {
    minHeight: '100%',
    paddingTop: 3,
    paddingBottom: 3,
  },
  card: {
    padding: 2,
  },
} satisfies SxPropStyles

type ChangeEmailDryData = ReturnType<typeof useMutationChangeEmailDry>['data']

export const EmailChange = () => {
  const [oldEmail, setOldEmail] = useState('')
  const [newEmail, setNewEmail] = useState('')
  const [dryRunResult, setDryRunResult] = useState<ChangeEmailDryData | null>(
    null,
  )

  const { mutate: changeEmailDry } = useMutationChangeEmailDry()
  const { mutate: changeEmail } = useMutationChangeEmail()

  const handleDryRun = () => {
    if (!(oldEmail && newEmail)) {
      return
    }
    changeEmailDry(
      { oldEmail, newEmail },
      {
        onSuccess: (data) => {
          setDryRunResult(data)
        },
        onError: () => {
          alert('失敗しました')
        },
      },
    )
  }

  const handleRun = () => {
    if (!(oldEmail && newEmail)) {
      return
    }
    if (prompt('本実行する場合は「本実行」と入力') !== '本実行') {
      return
    }
    changeEmail(
      { oldEmail, newEmail },
      {
        onSuccess: () => {
          alert('変更しました')
          setOldEmail('')
          setNewEmail('')
          setDryRunResult(null)
        },
        onError: () => {
          alert(
            '失敗しました。APIサーバーのログや、DBの`account_change_email`テーブルのログを確認してください。',
          )
        },
      },
    )
  }

  return (
    <Card sx={styles.card}>
      <Typography variant={'h5'}>アドレスの付け替え</Typography>
      <TextField
        label="旧メールアドレス"
        variant="outlined"
        fullWidth={true}
        margin="normal"
        value={oldEmail}
        onChange={(e) => setOldEmail(e.target.value)}
      />
      <TextField
        label="新メールアドレス"
        variant="outlined"
        fullWidth={true}
        margin="normal"
        value={newEmail}
        onChange={(e) => setNewEmail(e.target.value)}
      />
      <Button
        variant="contained"
        color="primary"
        fullWidth={true}
        onClick={handleDryRun}
        sx={{ mt: 2 }}
      >
        Dry-run
      </Button>
      {dryRunResult && (
        <Box sx={{ mt: 4 }}>
          <Typography variant={'h3'}>
            アドレス変更対象ユーザーの情報:
          </Typography>
          <Typography>
            QRコード:
            <a
              href={`${config.visitorSiteDomain}/qrcode/${dryRunResult.qrCodeId}`}
              target="_blank"
              rel="noopener noreferrer"
            >
              {dryRunResult.qrCodeId}
            </a>
          </Typography>
          <Typography>
            StripeId:
            <a
              href={`https://dashboard.stripe.com/customers/${dryRunResult.stripeCustomerId}`}
              target="_blank"
              rel="noopener noreferrer"
            >
              {dryRunResult.stripeCustomerId}
            </a>
          </Typography>

          <Typography variant={'h3'} sx={{ mt: 4 }}>
            移行先ユーザーの情報(削除されます):
          </Typography>
          <Typography sx={{ color: 'red' }}>
            QRコード:
            <a
              href={`${config.visitorSiteDomain}/qrcode/${dryRunResult.qrCodeIdTemp}`}
              target="_blank"
              rel="noopener noreferrer"
            >
              {dryRunResult.qrCodeIdTemp}
            </a>
          </Typography>
          <Typography sx={{ color: 'red' }}>
            StripeId:
            <a
              href={`https://dashboard.stripe.com/customers/${dryRunResult.stripeCustomerIdTemp}`}
              target="_blank"
              rel="noopener noreferrer"
            >
              {dryRunResult.stripeCustomerIdTemp}
            </a>
            (これがある場合は要注意)
          </Typography>
          <Button
            variant="contained"
            color="primary"
            fullWidth={true}
            onClick={handleRun}
            sx={{ mt: 4 }}
          >
            本実行
          </Button>
        </Box>
      )}
    </Card>
  )
}
