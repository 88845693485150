import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@mui/material'
import { type FC, type ReactNode, useState } from 'react'
import { useTranslation } from 'react-i18next'

export type ConfirmationOptions = {
  buttonTextPositive?: string
  buttonTextNegative?: string
  description: string | ReactNode
  title: string
  variant?: 'danger' | 'info'
}

type ConfirmationDialogProps = ConfirmationOptions & {
  onDecision: (decision?: boolean) => void
  onDissmissable: () => void
}

export const ConfirmationDialog: FC<ConfirmationDialogProps> = ({
  buttonTextPositive: buttonTextPositiveProps,
  buttonTextNegative: buttonTextNegativeProps,
  description,
  onDecision,
  onDissmissable,
  title,
  variant = 'info',
}) => {
  const [isOpen, setIsOpen] = useState(true)
  const { t } = useTranslation()

  const ButtonTextPositiveDefault = variant === 'info' ? t('Ok') : t('Yes')
  const buttonTextPositive =
    buttonTextPositiveProps || ButtonTextPositiveDefault
  const buttonTextNegative = buttonTextNegativeProps || t('Cancel')

  const makeDecision = (decision?: boolean) => {
    onDecision(decision)
    setIsOpen(false)
  }

  return (
    <Dialog
      open={isOpen}
      onClose={() => {
        makeDecision()
      }}
      TransitionProps={{
        onExited: () => onDissmissable(),
      }}
    >
      <DialogTitle> {title}</DialogTitle>
      <DialogContent>
        <DialogContentText>{description}</DialogContentText>
      </DialogContent>
      <DialogActions>
        {variant === 'danger' && (
          <>
            <Button color="primary" onClick={() => makeDecision(true)}>
              {buttonTextPositive}
            </Button>
            <Button
              color="primary"
              onClick={() => makeDecision(false)}
              autoFocus={true}
            >
              {buttonTextNegative}
            </Button>
          </>
        )}

        {variant === 'info' && (
          <Button color="primary" onClick={() => makeDecision(true)}>
            {buttonTextPositive}
          </Button>
        )}
      </DialogActions>
    </Dialog>
  )
}
