import type { Menu } from '@/features/api/types'
import { noCacheConfig } from '@/libraries/reactQuery/constants'
import { queryKeys } from '@/libraries/reactQuery/queryKeys'
import { trpcClient } from '@/libraries/trpc/trpcClient.ts'
import { useSuspenseQuery } from '@tanstack/react-query'

export const useQueryMenu = () => {
  return useSuspenseQuery({
    queryKey: queryKeys.menu(),
    queryFn: async (): Promise<Menu> => {
      const menu = await trpcClient.menu.getCurrent.query()
      return {
        id: menu.id,
        currency_code: menu.currencyCode,
        description: menu.description,
        font_type: menu.fontType,
        shop_name: menu.shopName,
        shop_postal_code: menu.shopPostalCode,
      }
    },
    ...noCacheConfig,
  })
}
