import { config } from '@/config'
import { initializeApp } from 'firebase/app'
import { getAuth } from 'firebase/auth'

const firebaseConfig = {
  apiKey: config.firebaseApiKey,
  appId: config.firebaseAppId,
  authDomain: config.firebaseAuthDomain,
  projectId: config.firebaseProjectId,
}

initializeApp(firebaseConfig)

export const firebaseAuth = getAuth()
