import { useMutationCreatePortalSession } from '@/features/account/api/useMutationCreatePortalSession'
import { css } from '@emotion/react'
import { Button, Typography } from '@mui/material'
import type { FC } from 'react'

type Props = {
  className?: string
}

export const SubscriptionUpdate: FC<Props> = (props) => {
  const { isPending, mutate, isError } = useMutationCreatePortalSession()

  const manegeCurrentPlan = () => {
    mutate()
  }

  return (
    <div {...props}>
      <Button
        disabled={isPending}
        variant="contained"
        onClick={manegeCurrentPlan}
      >
        有料プランを変更・解約する
      </Button>
      {isError && (
        <Typography color="error" variant="caption" css={styles.errorText}>
          エラーが発生しました
        </Typography>
      )}
    </div>
  )
}

const styles = {
  errorText: css`
    margin-left: 5px;
  `,
}
