import { trpcClient } from '@/libraries/trpc/trpcClient.ts'
import { useMutation } from '@tanstack/react-query'

type Args = { oldEmail: string; newEmail: string }

export const useMutationChangeEmail = () => {
  return useMutation({
    mutationFn: async (args: Args) => {
      const { oldEmail, newEmail } = args
      const result = await trpcClient.owner.changeEmail.mutate({
        oldEmail,
        newEmail,
      })
      return result
    },
  })
}
