import { FieldTitle } from '@/components/FieldTitle'
import { FormikErrors } from '@/components/FormikErrors'
import type { Genre, MaybeNew, Menuitem } from '@/features/api/types'
import { currencySettings } from '@/features/currency/_do_not_edit_currencySettings'
import {
  intPriceToRealPrice,
  realPriceToIntPrice,
} from '@/features/currency/_do_not_edit_currencyUtils'
import { useQueryMenu } from '@/features/menuSetting/api/useQueryMenu'
import { SquareDiv } from '@/features/menuitemDetail/SquareDiv'
import { useMutationUploadMenuitemImage } from '@/features/menuitemDetail/api/useMutationCreateMenuitemImage'
import { useMutationDeleteMenuitem } from '@/features/menuitemDetail/api/useMutationDeleteMenuitem'
import { useMutationUpsertMenuitem } from '@/features/menuitemDetail/api/useMutationUpsertMenuitem'
import { queryKeys } from '@/libraries/reactQuery/queryKeys'
import { unwrapCustomErrorPayload } from '@/libraries/trpc/unwrapCustomErrorPayload.ts'
import { removeLineBreaks } from '@/utils/removeLineBreaks.ts'
import { css } from '@emotion/react'
import CloseIcon from '@mui/icons-material/Close'
import {
  Box,
  Button,
  Card,
  CardContent,
  Divider,
  IconButton,
  Switch,
  TextField,
  Typography,
} from '@mui/material'
import { useQueryClient } from '@tanstack/react-query'
import { useFormik } from 'formik'
import { HTTPError } from 'ky'
import { useSnackbar } from 'notistack'
import type { FC } from 'react'
import Dropzone from 'react-dropzone'
import { useTranslation } from 'react-i18next'
import { NumericFormat } from 'react-number-format'
import { useNavigate } from 'react-router-dom'
import invariant from 'tiny-invariant'
// biome-ignore lint/style/noNamespaceImport: <explanation>
import * as Yup from 'yup'
import { getImgixSrc } from '../../libraries/imgix/getImgixSrc.ts'
import { useConfirmation } from '../confirmation/ConfirmationContext.tsx'
import { SquareImage } from './SquareImage.tsx'

interface Props {
  existingMenuitem?: Menuitem
  genres: Genre[]
  isNew: boolean
}

export const MenuitemDetailViewForm: FC<Props> = ({
  existingMenuitem,
  genres,
  isNew,
  ...rest
}) => {
  const { enqueueSnackbar } = useSnackbar()
  const { confirm } = useConfirmation()
  const navigate = useNavigate()
  const { t } = useTranslation()
  const queryClient = useQueryClient()

  const menuQuery = useQueryMenu()
  const { currency_code: currencyCode } = menuQuery.data

  const { isPending: isUpdating, mutate: mutateUpdate } =
    useMutationUpsertMenuitem()

  const { isPending: isDeleting, mutate: mutateDelete } =
    useMutationDeleteMenuitem()

  //   'menuitems',
  //   {
  //     onSuccess: () => {
  //       refetchquery
  //       enqueueSnackbar(t('Deleted'), { variant: 'success' });
  //       navigate('/app/management/menuitems/', { replace: true });
  //     },
  //   },
  // );

  const deleteMenuitem = async () => {
    // もうちょっときれいなやり方にしたい
    invariant(existingMenuitem, '削除対象のメニューアイテムのIDがありません')

    const yes = await confirm({
      buttonTextNegative: t('Cancel'),
      buttonTextPositive: t('Delete'),
      description: t('ui_products:deletion confirmation', {
        name: existingMenuitem.name,
      }),
      title: t('Deletion'),
      variant: 'danger',
    })
    if (!yes) {
      return
    }

    mutateDelete(existingMenuitem.id, {
      onSuccess: () => {
        queryClient.invalidateQueries({ queryKey: queryKeys.menuitemsAll() })
        enqueueSnackbar(t('Deleted'), { variant: 'success' })
        navigate('/app/management/menuitems/', { replace: true })
      },
    })
  }

  let initialValues: MaybeNew<Menuitem>
  if (isNew) {
    initialValues = {
      description: '',
      description_detail: '',
      genre: '',
      // id
      imgix_filename: '',
      is_hidden: false,
      name: '',
      order_num: 10000,
      price: 0,
      sold_out: false,
    }
  } else {
    initialValues = {
      // @ts-expect-error TS18048
      description: existingMenuitem.description,
      // @ts-expect-error TS18048
      description_detail: existingMenuitem.description_detail,
      // @ts-expect-error TS18048
      genre: existingMenuitem.genre,
      // @ts-expect-error TS18048
      id: existingMenuitem.id,
      // @ts-expect-error TS18048
      imgix_filename: existingMenuitem.imgix_filename,
      // @ts-expect-error TS18048
      is_hidden: existingMenuitem.is_hidden,
      // @ts-expect-error TS18048
      name: existingMenuitem.name,
      // @ts-expect-error TS18048
      order_num: existingMenuitem.order_num,
      // @ts-expect-error TS18048
      price: existingMenuitem.price,
      // @ts-expect-error TS18048
      sold_out: existingMenuitem.sold_out,
    }
  }

  const {
    errors,
    handleBlur,
    handleChange,
    handleSubmit,
    setFieldValue,
    touched,
    values,
  } = useFormik<MaybeNew<Menuitem>>({
    enableReinitialize: true,
    initialValues,
    onSubmit: (formValues) => {
      mutateUpdate(formValues, {
        onSuccess: async (savedProductId) => {
          const message = isNew ? t('Added') : t('Updated')
          enqueueSnackbar(message, { variant: 'success' })
          await queryClient.invalidateQueries({
            queryKey: queryKeys.menuitemsAll(),
          })
          if (isNew) {
            navigate(`/app/management/menuitems/${savedProductId}/`, {
              replace: true,
            })
          }
        },
        onError: (e) => {
          const payload = unwrapCustomErrorPayload(e)
          switch (payload?.type) {
            case 'product.exceedsProductLimit':
              return enqueueSnackbar(
                `商品数の上限を超えました。上限は${payload.limit}個です。`,
                {
                  variant: 'error',
                },
              )
            default:
              return enqueueSnackbar('エラーが発生しました', {
                variant: 'error',
              })
          }
        },
      })
    },
    validationSchema: Yup.object().shape({
      description: Yup.string().max(200),
      description_detail: Yup.string().max(280),
      genre: Yup.string(),
      imgix_filename: Yup.string(),
      name: Yup.string().max(50).required(),
      order_num: Yup.number().min(0).max(2147483647).required(),
      price: Yup.number().max(10000000).required(),
      sold_out: Yup.boolean().required(),
    }),
  })

  const { isPending: isImageUploading, mutate: uploadImage } =
    useMutationUploadMenuitemImage()

  const onImageDropped = async (files: File[]) => {
    const file = files[0]
    invariant(file, 'ファイルがありません')
    uploadImage(file, {
      onSuccess: ({ fileName }) => {
        setFieldValue('imgix_filename', fileName)
      },
      onError: async (e) => {
        if (!(e instanceof HTTPError)) {
          return
        }
        switch ((await e.response.json()).message) {
          case 'bad mime type':
            return alert(
              'サポートされていない画像形式です。使用可能な形式はJPEG/PNG/WEBP/HEICの４種類です。',
            )
          case 'file is too large':
            return alert('ファルサイズが上限の10MBを超えています')
          default:
            return alert('ファイルのアップロード時にエラーが発生しました')
        }
      },
    })
  }

  const image = (
    <div css={styles.image}>
      <SquareImage
        alt={t('Product')}
        src={getImgixSrc(values.imgix_filename, {
          width: 500,
        })}
      />
      <IconButton
        css={styles.imageDeleteButton}
        onClick={() => setFieldValue('imgix_filename', '')}
        size="small"
      >
        <CloseIcon fontSize="large" />
      </IconButton>
    </div>
  )

  const imageDropZone = (
    <Dropzone onDrop={onImageDropped}>
      {({ getRootProps, getInputProps, isDragActive }) => (
        <SquareDiv {...getRootProps()}>
          <div
            css={css`
              ${styles.imageDropzone};
              border-color: ${isDragActive ? '#2196f3' : '#eeeeee'};
            `}
          >
            <input {...getInputProps()} accept="image/*" />
            {isImageUploading ? (
              'uploading...'
            ) : (
              <Typography>
                {t('ui_products:Tap')}
                <br />
                {t('or')}
                <br />
                {t('ui_products:Drag image here')}
              </Typography>
            )}
          </div>
        </SquareDiv>
      )}
    </Dropzone>
  )

  const emptySelection = {
    value: '',
    label: `(${t('Unspecified')})`,
  }

  const genreOptions = [
    emptySelection,
    ...genres.map((genre) => ({
      value: genre.id,
      label: genre.name,
    })),
  ]

  // 通貨の設定に応じて適切な価格表示がされるようにする
  const { realPrice, currencySymbol, decimalScale } = (() => {
    const currencySetting = currencySettings[currencyCode]
    invariant(currencySetting, 'currency setting was not found')
    const currencySymbol = currencySetting.symbol
    const decimalScale = currencySetting.decimalScale
    const realPrice = intPriceToRealPrice(values.price, decimalScale)

    return { realPrice, currencySymbol, decimalScale }
  })()

  return (
    <form onSubmit={handleSubmit} {...rest}>
      <Card>
        <CardContent>
          <div css={styles.grid}>
            <div>
              <FieldTitle title={`${t('Product name')} *`} />
              <Box mt={1}>
                <TextField
                  error={Boolean(touched.name && errors.name)}
                  fullWidth={true}
                  // TODO: 具体的な名前に修正したい
                  name="name"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  required={true}
                  value={values.name}
                  variant="outlined"
                />
              </Box>
            </div>
            <div>
              <FieldTitle title={`${t('Price')} *`} />
              <Box mt={1}>
                <NumericFormat
                  className="mycurrency"
                  css={[
                    styles.priceInput,
                    Boolean(touched.price && errors.price) &&
                      styles.priceInputOnError,
                  ]}
                  decimalScale={decimalScale}
                  fixedDecimalScale={true}
                  inputMode="decimal"
                  onBlur={handleBlur}
                  onValueChange={(values) => {
                    const updatedRealPrice = values.floatValue
                    const updatedIntPrice = realPriceToIntPrice(
                      updatedRealPrice,
                      decimalScale,
                    )
                    setFieldValue('price', updatedIntPrice)
                  }}
                  prefix={`${currencySymbol} `}
                  thousandSeparator={true}
                  value={realPrice}
                  //
                  // InputHTMLAttributes由来
                  //
                  name="price"
                  required={true}
                />
              </Box>
            </div>
            <div>
              <FieldTitle
                title={t('Genre')}
                tooltipText={t('ui_products:Hint of genre')}
              />
              <Box mt={1}>
                <TextField
                  error={Boolean(touched.genre && errors.genre)}
                  fullWidth={true}
                  name="genre"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  select={true}
                  SelectProps={{ native: true }}
                  value={values.genre}
                  variant="outlined"
                >
                  {genreOptions.map((genreOption) => (
                    <option key={genreOption.value} value={genreOption.value}>
                      {genreOption.label}
                    </option>
                  ))}
                </TextField>
              </Box>
            </div>
            <div>
              <FieldTitle
                title={t('Sort number')}
                tooltipText={t('ui_products:Hint of sort number')}
              />
              <Box mt={1}>
                <TextField
                  error={Boolean(touched.order_num && errors.order_num)}
                  fullWidth={true}
                  slotProps={{
                    input: { inputMode: 'numeric' },
                  }}
                  type="number"
                  name="order_num"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  required={true}
                  value={values.order_num}
                  variant="outlined"
                />
              </Box>
            </div>

            <div>
              <FieldTitle
                title={t('Additional description')}
                tooltipText={t('ui_products:Hint for additional description')}
              />
              <Box mt={1}>
                <TextField
                  error={Boolean(touched.description && errors.description)}
                  fullWidth={true}
                  multiline={true}
                  name="description"
                  onBlur={handleBlur}
                  onChange={(e) => {
                    // multilineにすることで入力をしやすくしつつ、改行は入力させたくないという要件を満たすため、手動で処理している
                    setFieldValue(
                      'description',
                      removeLineBreaks(e.target.value),
                    )
                  }}
                  value={values.description}
                  variant="outlined"
                />
              </Box>
            </div>
            <div>
              <FieldTitle
                title={t('Detailed description')}
                tooltipText={t('ui_products:Hint for detailed description')}
              />
              <Box mt={1}>
                <TextField
                  error={Boolean(touched.description && errors.description)}
                  fullWidth={true}
                  multiline={true}
                  name="description_detail"
                  onBlur={handleBlur}
                  onChange={(e) => {
                    // multilineにすることで入力をしやすくしつつ、改行は入力させたくないという要件を満たすため、手動で処理している
                    setFieldValue(
                      'description_detail',
                      removeLineBreaks(e.target.value),
                    )
                  }}
                  value={values.description_detail}
                  variant="outlined"
                />
              </Box>
            </div>
            <div
              css={css`
                position: relative;
              `}
            >
              <Box display="flex" alignItems="center">
                <Switch
                  checked={values.is_hidden}
                  color="secondary"
                  edge="start"
                  name="is_hidden"
                  onChange={handleChange}
                  value={values.is_hidden}
                />
                <FieldTitle
                  title={t('Hidden')}
                  tooltipText={t('ui_products:Hint for hiding')}
                />
              </Box>

              <Box display="flex" alignItems="center">
                <Switch
                  checked={values.sold_out}
                  color="secondary"
                  disabled={values.is_hidden}
                  edge="start"
                  name="sold_out"
                  onChange={handleChange}
                  value={values.sold_out}
                />
                <FieldTitle
                  title={t('Sold out')}
                  tooltipText={t('ui_products:Hint for selling out')}
                />
              </Box>
            </div>
            <div css={styles.photoGridItem}>
              <FieldTitle title={t('Photo')} />
              <Box mt={1}>
                <div css={styles.imageContainer}>
                  {values.imgix_filename ? image : imageDropZone}
                </div>
              </Box>
            </div>
          </div>

          <Divider css={styles.divider} />
          <FormikErrors touched={touched} errors={errors} />
          <Box mt={2} display="flex" justifyContent="flex-end">
            <Button
              variant="contained"
              color="secondary"
              type="submit"
              disabled={isUpdating}
            >
              {isNew ? t('Add') : t('Update')}
            </Button>
          </Box>
        </CardContent>
      </Card>

      {!isNew && (
        <Box mt={2}>
          <Button
            onClick={deleteMenuitem}
            disabled={isDeleting}
            aria-label="delete"
            size="small"
            tabIndex={-1}
          >
            {t('Delete')}
          </Button>
        </Box>
      )}
    </form>
  )
}

const styles = {
  grid: css`
      align-items: flex-start;
      display: grid;
      grid-template-columns: 1fr;
      grid-gap: 1.5rem;
      @media (min-width: 960px) {
        grid-template-columns: 1fr 1fr;
      }
    `,
  // react-number-formatとmaterial-uiのTextFieldを組み合わせると、
  // モバイル端末からの入力時に文字がダブって重複される問題があり、解決できなかった。
  // このため、react-number-formatのスタイルをmui風に上書きすることにした。
  priceInput: css`
      border-radius: 4px;
      border: #aaa 1px solid;
      font-size: 16px;
      font-family: Roboto, Helvetica, Arial, "Noto Sans JP", sans-serif;
      height: 56px;
      padding: 0 12px;
      width: 100%;

      :hover {
          border: rgb(38, 50, 56) 1px solid;
      }

      :focus {
          border: #EB6442 2px solid;
          outline: none;
      }
  `,
  priceInputOnError: css`
      border: #c23f38 1px solid;
  `,
  photoGridItem: css`
      @media (min-width: 960px) {
        grid-column: 2 / 3;
        grid-row: 3 / 6;
      }
    `,
  imageContainer: css`
      max-width: 260px;
    `,
  image: css`
      background: #fafafa;
      position: relative;
    `,
  imageDeleteButton: css`
      background: rgba(84, 110, 122, 0.1);
      color: white;
      margin: 0.5rem;
      position: absolute;
      right: 0;
      top: 0;
      :hover {
        background: rgba(84, 110, 122, 0.2);
      }
    `,
  imageDropzone: css`
      align-items: center;
      background-color: #fafafa;
      border-radius: 2px;
      border-style: dashed;
      border-width: 2px;
      color: #bdbdbd;
      cursor: pointer;
      display: flex;
      height: 100%;
      justify-content: center;
      outline: none;
      padding: 20px;
      text-align: center;
      transition: border 0.24s ease-in-out;
    `,
  divider: css`
      margin-top: 1rem;
    `,
}
