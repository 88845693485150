import type { Backup } from '@/features/api/types'
import { trpcClient } from '@/libraries/trpc/trpcClient.ts'
import { useMutation } from '@tanstack/react-query'

export const useMutationUpdateBackup = () => {
  return useMutation({
    mutationFn: (unsavedItem: Backup) => {
      return trpcClient.backup.update.mutate(unsavedItem)
    },
  })
}
