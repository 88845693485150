import Page from '@/features/layout/Page'
import { Box, Container } from '@mui/material'
import type { FC } from 'react'
import { useTranslation } from 'react-i18next'

import { GenreListViewHeader } from '@/features/genreList/GenreListViewHeader'
import { GenreListViewResults } from '@/features/genreList/GenreListViewResults'

const styles = {
  container: {
    paddingTop: 3,
    paddingBottom: 3,
  },
}

const GenreListView: FC = () => {
  const { t } = useTranslation()

  return (
    <Page sx={styles.container} title={t('Genres')}>
      <Container maxWidth={false}>
        <GenreListViewHeader />
        <Box mt={3}>
          <GenreListViewResults />
        </Box>
      </Container>
    </Page>
  )
}

export default GenreListView
