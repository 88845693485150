import type { Genre, MaybeNew } from '@/features/api/types'
import { trpcClient } from '@/libraries/trpc/trpcClient.ts'
import { isNonNullable } from '@/utils/isNonNullable.ts'
import { useMutation } from '@tanstack/react-query'

export function useMutationUpsertGenre() {
  return useMutation({
    mutationFn: async (unsavedItem: MaybeNew<Genre>): Promise<string> => {
      const commonArgs = {
        name: unsavedItem.name,
        description: unsavedItem.description,
        isHidden: unsavedItem.is_hidden,
        layoutType: unsavedItem.layout_type,
        orderNum: unsavedItem.order_num,
      }

      // 更新時
      if (isNonNullable(unsavedItem.id)) {
        const response = await trpcClient.genre.update.mutate({
          ...commonArgs,
          id: unsavedItem.id,
        })
        return response.id
      }

      // 以下、新規作成時
      const response = await trpcClient.genre.create.mutate(commonArgs)
      return response.id
    },
  })
}
