import type { SxPropStyles } from '@/libraries/mui/muiTypes.ts'
import { Box } from '@mui/material'
import type { ReactNode } from 'react'

type Props = {
  children: ReactNode
  lines?: number
}

const styles = {
  container: {
    overflow: 'hidden',
    display: '-webkit-box',
    WebkitBoxOrient: 'vertical',
    lineHeight: 1.5, // こうしとくと2行になっても行の高さが変わらずにすむ
  },
} satisfies SxPropStyles

/**
 * MuiのTable Cell内でテキストをクランプするコンポーネント
 * 他の場所でも使えるかは未確認
 */
export const ClampText = (props: Props) => {
  const { children, lines = 2 } = props

  return (
    <Box sx={[styles.container, { WebkitLineClamp: lines }]}>{children}</Box>
  )
}
