import type { Genre } from '@/features/api/types'
import { noCacheConfig } from '@/libraries/reactQuery/constants'
import { queryKeys } from '@/libraries/reactQuery/queryKeys'
import { trpcClient } from '@/libraries/trpc/trpcClient.ts'
import { useSuspenseQuery } from '@tanstack/react-query'

export function useQueryGenre(id: string, enabled: boolean) {
  return useSuspenseQuery({
    queryKey: queryKeys.genre(id),
    queryFn: async () => {
      if (!enabled) {
        return null
      }
      const rawData = await trpcClient.genre.get.query({ id })
      const result: Genre = {
        description: rawData.description,
        id: rawData.id,
        is_hidden: rawData.isHidden,
        layout_type: rawData.layoutType,
        name: rawData.name,
        order_num: rawData.orderNum,
      }
      return result
    },
    ...noCacheConfig,
  })
}
