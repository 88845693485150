import { Alert, AlertTitle, Box, Link } from '@mui/material'
import { Link as RouterLink } from 'react-router-dom'

type Props = {
  title: string
}

export const ForcePaymentNotification = (prop: Props) => {
  const { title } = prop
  return (
    <Box mt={3}>
      <Alert variant="outlined" severity="warning">
        <AlertTitle>{title}</AlertTitle>
        継続して利用される場合は
        <Link
          variant="h5"
          color="inherit"
          to="/app/account"
          component={RouterLink}
          underline="always"
        >
          アカウント
        </Link>
        から有料プランへのご登録をお願いいたします。
      </Alert>
    </Box>
  )
}
