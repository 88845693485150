import { css } from '@emotion/react'
import { Tooltip, Typography } from '@mui/material'
import type React from 'react'

type Props = {
  title: string
}

export const QuestionTooltip: React.FC<Props> = ({ title }) => {
  return (
    <Tooltip
      title={title}
      placement="bottom-start"
      enterTouchDelay={0}
      leaveTouchDelay={5000}
    >
      <div
        css={css`
          cursor: pointer;
          align-items: center;
          background: rgba(0, 0, 0, 0.25);
          border-radius: 50%;
          color: white;
          display: inline-flex;
          height: 0.85rem;
          justify-content: center;
          line-height: 0;
          width: 0.85rem;
          padding: 0.3rem;
        `}
      >
        <Typography
          css={css`
            font-size: 0.7rem;
            font-weight: bold;
          `}
          variant="body2"
        >
          ?
        </Typography>
      </div>
    </Tooltip>
  )
}
