import { sendMenuUpdatedMessage } from '@/features/menuPreviewer/sendMenuUpdatedMessage'
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import { ReactQueryDevtools } from '@tanstack/react-query-devtools'
import type React from 'react'

type Props = {
  children?: React.ReactNode
}

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry: false,
    },
    mutations: {
      onSettled: () => {
        // update preview window
        sendMenuUpdatedMessage()
      },
    },
  },
})

export const ReactQuery: React.FC<Props> = ({ children }) => {
  return (
    <QueryClientProvider client={queryClient}>
      {!!import.meta.env['VITE_APP_TAN_STACK_DEVTOOLS'] && (
        <ReactQueryDevtools />
      )}
      {children}
    </QueryClientProvider>
  )
}
