import { YouTube } from './mdxComponents/YouTube.tsx'

#### 2024-10-10

管理画面のUI/UXの細かな改善を行いました。

- 商品やジャンルの一覧画面で、名前が長い場合にセルが縦方向に無限に拡張して見づらくなっていました。このため、長文は省略表記するようにしました。
- 一覧画面の補足説明列を右端に移動し、より重要度の高い情報を左側に配置するようにしました。
- 商品の補足説明及び調査説明が長文になる場合に、全体が見えず入力しづらい問題を解消しました。なお、デザインの制約上、商品の説明において改行は使用できないのは従来と変更ありません。
- これまでは商品を「非表示」にしたときに「売り切れ」の設定項目は隠されていましたが、迷いが生じるため常に表示するようにしました。

#### 2024-10-04 21:09

一部のお客様において管理画面がエラーになる問題が発生していましたが、復旧しました。ご迷惑をおかけし申し訳ございませんでした。

#### 2024-09-10

モバイル端末から商品の価格を入力した際に、文字が重複して入力される問題を修正しました。

#### 2024-08-16

- メニュー一覧画面のジャンル列をクリックすると、そのジャンルの詳細ページに遷移できるようになりました。
<YouTube url="https://www.youtube.com/embed/HpRjrS_BKJg?si=sQ2g70VipazTyXs0" />
