import { Box, type Theme } from '@mui/material'
import type { FC } from 'react'

export const Blockquote: FC = (props) => {
  return (
    <Box
      component="blockquote"
      sx={(theme: Theme) => ({
        paddingLeft: theme.spacing(2),
        paddingBottom: theme.spacing(1),
        paddingTop: theme.spacing(1),
        marginBottom: theme.spacing(2),
        borderLeft: `4px solid ${theme.palette.text.secondary}`,
        '& > p': {
          color: theme.palette.text.secondary,
          marginBottom: 0,
        },
      })}
      {...props}
    />
  )
}
