import { css } from '@emotion/react'
import CloseIcon from '@mui/icons-material/Close'
import { IconButton } from '@mui/material'
import { SnackbarProvider } from 'notistack'
import { type ReactNode, useRef } from 'react'

export const MySnackbarProvider = (props: { children: ReactNode }) => {
  const { children } = props
  const notistackRef = useRef<SnackbarProvider>(null)

  return (
    <SnackbarProvider
      action={(key) => (
        <IconButton
          onClick={() => {
            notistackRef.current?.closeSnackbar(key)
          }}
          size="small"
        >
          <CloseIcon
            css={css`
              color: #f0f0f0;
            `}
          />
        </IconButton>
      )}
      autoHideDuration={3000}
      dense={true}
      maxSnack={1}
      ref={notistackRef}
    >
      {children}
    </SnackbarProvider>
  )
}
