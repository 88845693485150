import type { Qrcode } from '@/features/api/types.ts'
import { queryKeys } from '@/libraries/reactQuery/queryKeys.ts'
import { trpcClient } from '@/libraries/trpc/trpcClient.ts'
import { useSuspenseQuery } from '@tanstack/react-query'

export const useQueryCurrentQrCode = () => {
  return useSuspenseQuery({
    queryKey: queryKeys.qrcode(),
    queryFn: async (): Promise<Qrcode> => {
      const qrCode = await trpcClient.qrCode.getCurrent.query()
      return qrCode
    },
  })
}
