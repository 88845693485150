import type { UserInfo } from '@/features/auth/state/authStatus'

/**
 * Playwrightでのテスト時にのみ使用されるダミーのユーザー情報
 */
export const mockedAuthorizedUserInfo: UserInfo = {
  type: '__authorizedUserInfo',
  id: 'random_id_for_test',
  uid: 'random_uid_for_test',
  avatar: '/static/images/avatar_for_test.jpg',
  email: 'random_email_for_test',
  // @ts-expect-error テストなので気にしない
  firebaseUser: {},
  name: 'random_name_for_test',
  plan: 'basic',
  registration_date: 'random_registration_date_for_test',
}
