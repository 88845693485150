import type { Backup } from '@/features/api/types'
import { useMutationUpdateBackup } from '@/features/backup/api/useMutationUpdateBackup'
import { queryKeys } from '@/libraries/reactQuery/queryKeys'
import { TextField } from '@mui/material'
import { useQueryClient } from '@tanstack/react-query'
import { type FC, useState } from 'react'
import { useTranslation } from 'react-i18next'

interface Props {
  backup: Backup
}

export const MemoColumn: FC<Props> = ({ backup }) => {
  const [isEditMode, setIsEditMode] = useState(false)
  const [value, setValue] = useState(backup.memo)
  const { isPending: isUpdatingBackup, mutate: updateBackup } =
    useMutationUpdateBackup()
  const { t } = useTranslation()
  const queryClient = useQueryClient()

  const onValueFixed = () => {
    const isValueChanged = value !== backup.memo
    const valueExists = value.trim() !== ''
    if (isValueChanged && valueExists) {
      updateBackup(
        { ...backup, memo: value },
        {
          onSuccess: async () => {
            await queryClient.invalidateQueries({
              queryKey: queryKeys.backups(),
            })
            setIsEditMode(false)
          },
        },
      )
    } else {
      // 空白文字が入力されたときは値をもとに戻しておく
      setValue(backup.memo)
      setIsEditMode(false)
    }
  }

  if (isEditMode) {
    return (
      <TextField
        disabled={isUpdatingBackup}
        value={value}
        onBlur={onValueFixed}
        onChange={(e) => setValue(e.target.value)}
        onKeyDown={(e) => {
          if (e.keyCode === 13) {
            onValueFixed()
          }
        }}
        autoFocus={true}
      />
    )
  }

  const valueToDisplay =
    value === '' ? `(${t('ui_backup:Click to add memo')})` : backup.memo

  // biome-ignore lint/a11y/useKeyWithClickEvents: <explanation>
  return <div onClick={() => setIsEditMode(true)}>{valueToDisplay}</div>
}
