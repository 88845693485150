import { useQueryVisitorSiteInfo } from '@/features/api/commonQueries/useQueryVisitorSiteInfo'
import { toolBarHeight } from '@/features/layoutDashboard/TopBar'
import { useMenuPreviewer } from '@/features/menuPreviewer/useMenuPreviewer'
import { usePrevious } from '@/utils/usePrevious'
import { css } from '@emotion/react'
import { Box, Button } from '@mui/material'
import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useLocation } from 'react-router-dom'
import { MenuPreviewerPhone } from './MenuPreviewerPhone.tsx'

const targetUrls = [
  '/app/management/menuitems',
  '/app/management/genres',
  '/app/management/menu',
]

export const MenuPreviewer = () => {
  const { visitorSiteUrl } = useQueryVisitorSiteInfo()
  const { t } = useTranslation()
  const location = useLocation()

  const {
    isOpen,
    close: closePreview,
    restore: restorePreview,
    closeTemporary: closePreviewTemporary,
  } = useMenuPreviewer()

  const match = targetUrls.some((targetUrl) =>
    location.pathname.startsWith(targetUrl),
  )
  const prevMatch = usePrevious(match)

  useEffect(() => {
    const matchChanged = match !== prevMatch
    if (matchChanged) {
      if (match) {
        restorePreview()
      } else {
        closePreviewTemporary()
      }
    }
  }, [closePreviewTemporary, match, prevMatch, restorePreview])

  if (!isOpen) {
    return null
  }

  return (
    <div
      css={css`
        background: white;
        border-left: 1px solid rgba(0, 0, 0, 0.12);
        height: calc(100vh - ${toolBarHeight}px);
        padding: 1.5rem;
        position: fixed;
        right: 0;
        top: ${toolBarHeight}px;
      `}
    >
      <MenuPreviewerPhone />
      <Box mt={2}>
        <Button
          color="inherit"
          onClick={() => {
            closePreview()
          }}
          variant="contained"
          fullWidth={true}
        >
          {t('Close preview')}
        </Button>
      </Box>
      <Box mt={2}>
        <Button
          color="inherit"
          href={visitorSiteUrl}
          target="_blank"
          variant="contained"
          fullWidth={true}
        >
          {t('Open in another window')}
        </Button>
      </Box>
    </div>
  )
}
