import { css } from '@emotion/react'
import type React from 'react'
import { forwardRef } from 'react'

type Props = {} & React.ImgHTMLAttributes<HTMLImageElement>

// biome-ignore lint/suspicious/noExplicitAny: <explanation>
const ForwardableSquareImage: React.ForwardRefRenderFunction<any, Props> = (
  { className, ...rest },
  ref,
) => {
  const styles = {
    imageContainer: css`
      outline: none;
      overflow: hidden;
      position: relative;
      width: 100%;
      /* make it square */
      /* https://spin.atomicobject.com/2015/07/14/css-responsive-square/ */
      :after {
        content: '';
        display: block;
        padding-bottom: 100%;
      }
    `,
    image: css`
      object-fit: cover;
      position: absolute;
      width: 100%;
      height: 100%;
    `,
  }

  return (
    <div className={className} css={styles.imageContainer} ref={ref}>
      {/* biome-ignore lint/a11y/useAltText: <explanation> */}
      <img {...rest} css={styles.image} draggable="false" />
    </div>
  )
}

export const SquareImage = forwardRef(ForwardableSquareImage)
