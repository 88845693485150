import { css } from '@emotion/react'
import { Typography } from '@mui/material'
import type React from 'react'

type Props = {
  touched: { [key in string]: boolean }
  errors: { [key in string]: string }
}

export const FormikErrors: React.FC<Props> = ({ touched, errors }) => {
  const touchedKeys = Object.entries(touched)
    .filter(([, isThisKeyTouched]) => isThisKeyTouched)
    .map(([key]) => key)

  const errorMessages: string[] = []
  // biome-ignore lint/complexity/noForEach: <explanation>
  touchedKeys.forEach((touchedKey) => {
    const message = errors[touchedKey]
    if (message) {
      errorMessages.push(message)
    }
  })

  return (
    <ul
      css={css`
        list-style: none;
      `}
    >
      {errorMessages.map((message) => (
        <li key={message}>
          <Typography variant="caption" color="error">
            {message}
          </Typography>
        </li>
      ))}
    </ul>
  )
}
