import {
  Card,
  CardContent,
  CardHeader,
  Divider,
  Typography,
} from '@mui/material'
import type { FC } from 'react'
import { useTranslation } from 'react-i18next'

export const SupportViewGeneral: FC = (props) => {
  const { t } = useTranslation()

  return (
    <form>
      <Card {...props}>
        <CardHeader title={t('ui_support:Support via email')} />
        <Divider />
        <CardContent>
          <Typography>
            {t(
              'ui_support:Please contact support if you have any Questions, Feature requests or Bug reports.',
            )}
            <br />
            <a
              target="_blank"
              rel="noopener noreferrer"
              href="mailto:support@goodmenu.io"
            >
              support@goodmenu.io
            </a>
          </Typography>
        </CardContent>
      </Card>
    </form>
  )
}
