import { MailVerifyButton } from '@/features/account/MailVerifyButton'
import { useUserInfo } from '@/features/auth/state/authStatus'
import { Alert, AlertTitle, Box } from '@mui/material'
import type { FC } from 'react'
import { useTranslation } from 'react-i18next'

interface Props {
  className?: string
}

export const MenuitemListViewCautionMailVerification: FC<Props> = ({
  ...rest
}) => {
  const userInfo = useUserInfo()

  const { emailVerified } = userInfo.firebaseUser
  const { t } = useTranslation()

  if (emailVerified) {
    return null
  }

  return (
    <Box mt={3} {...rest}>
      <Alert variant="outlined" severity="warning">
        <AlertTitle>{t('ui_products:Email is not verified.')}</AlertTitle>
        {t(
          'ui_products:Click the button and click the link in the email you received.',
        )}
        <br />
        {t(
          'ui_products:Note that the service will stop after one month without email verification.',
        )}
        <Box mt={1}>
          <MailVerifyButton />
        </Box>
      </Alert>
    </Box>
  )
}
