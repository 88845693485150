import { GlobalStyles as GlobalStylesRaw } from '@mui/material'
import type { FC } from 'react'

const GlobalStyles: FC = () => {
  return (
    <GlobalStylesRaw
      styles={{
        '*': {
          boxSizing: 'border-box',
          margin: 0,
          padding: 0,
        },
        html: {
          height: '100%',
          width: '100%',
        },
        body: {
          height: '100%',
          width: '100%',
        },
        '#root': {
          height: '100%',
          width: '100%',
        },
      }}
    />
  )
}

export default GlobalStyles
