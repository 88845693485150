import { css } from '@emotion/react'

export const SwingingBell = () => {
  return <div css={styles.bell}>🔔</div>
}

const styles = {
  bell: css`
    margin-left: 8px;
    animation: bellSwing 0.5s ease-in-out infinite alternate;
    @keyframes bellSwing {
      0% {
        transform: rotate(-15deg);
      }
      100% {
        transform: rotate(15deg);
      }
    }
  `,
}
